<template>
  <div class="col-md-10">
    <h2 class="pb-2">Value addition and Manufacturing</h2>
    <hr />
    <div class="form-group">
      <label for="raw material"
        >Which type of raw material does your project deal with?
      </label>
      <select v-model="formData.rawMaterial" class="form-control">
        <option value="Primary products">Primary products</option>
        <option value="semi-processed">semi-processed</option>
        <option value="processed products">processed products</option>
      </select>
    </div>
    <div class="form-group">
      <label for="project directly"
        >Will the project directly result in the mass production of value-added
        goods ?
      </label>
      &nbsp; &nbsp;
      <div class="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="customRadioInline1"
          name="customRadioInline1"
          class="custom-control-input"
          v-model="formData.resultInMassProductionOfValueAddedGoods"
        />
        <label class="custom-control-label" for="customRadioInline1">Yes</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="customRadioInline2"
          name="customRadioInline1"
          class="custom-control-input"
          v-model="formData.resultInMassProductionOfValueAddedGoods"
        />
        <label class="custom-control-label" for="customRadioInline2">No</label>
      </div>
    </div>
    <div class="form-group">
      <label for="project directly"
        >Will the project connect to/ be part of an industrial or economic
        zone?</label
      >
      &nbsp; &nbsp;
      <div class="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="customRadioInline1"
          name="customRadioInline1"
          class="custom-control-input"
          v-model="formData.connectToIndustrialOrEconomicZone"
        />
        <label class="custom-control-label" for="customRadioInline1">Yes</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="customRadioInline2"
          name="customRadioInline1"
          class="custom-control-input"
          v-model="formData.connectToIndustrialOrEconomicZone"
        />
        <label class="custom-control-label" for="customRadioInline2">No</label>
      </div>
    </div>
    <div class="form-group">
      <label for="specific industrialization"
        >Indicate the specific industrialization areas that the project seeks to
        promote</label
      >
      <textarea
        class="form-control"
        id="specific industrialization"
        v-model="formData.industrializationAreasSeekToPromote"
        placeholder="Indicate the specific industrialization areas that the project seeks to promote"
        rows="3"
      ></textarea>
    </div>
    <div class="form-group">
      <label for="raw material"
        >What is the type of value addition that your project creates</label
      >
      <select v-model="typeOfValidationAddition" class="form-control">
        <option>Agro</option>
        <option>Packing</option>
      </select>
    </div>
    <div class="form-group">
      <label for="raw material">What is the source of materials</label>
      <select v-model="formData.sourceOfMaterials" class="form-control">
        <option>Ghana</option>
        <option>Togo</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            formData: {
                rawMaterial:"",
                resultInMassProductionOfValueAddedGoods:true,
                connectToIndustrialOrEconomicZone:true,
                industrializationAreasSeekToPromote:"",
                typeOfValidationAddition:"",
                sourceOfMaterials:""
            }
        }
    },
};
</script>

<style lang="scss" scoped>
</style>