<template>
  <form @input="emitFormData" class="col-md-10">
    <h2 class="pb-2">African Value Chain</h2>
    <hr />
    <label for=""
      >Which countries do you source your inputs from? Indicate the percentage
      of inputs that will be sourced per country
    </label>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="share-holder">Select Country</label>
        <select id="inputState" class="form-control">
          <option selected>Choose...</option>
          <option>Ghana</option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <label for="percentage">Percentage</label>
        <input
          type="text"
          class="form-control"
          placeholder="Enter percentage here..."
          v-model="form.countriesSourceInputsFromAndPercentage"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <select id="inputState" class="form-control">
          <option selected>Choose...</option>
          <option>Ghana</option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <input
          type="text"
          class="form-control"
          placeholder="Enter percentage here..."
          v-model="form.countriesSourceInputsFromAndPercentage"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <select id="inputState" class="form-control">
          <option selected>Choose...</option>
          <option>Ghana</option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <input
          type="text"
          class="form-control"
          placeholder="Enter percentage here..."
          v-model="form.countriesSourceInputsFromAndPercentage"
        />
      </div>
    </div>

    <div class="form-group">
      <label for="inputAddress"
        >What inputs do you source out of Africa (materials, technology,
        management)</label
      >
      <select 
      v-model="form.inputsSourced"
      @blur="$v.form.inputsSourced.$touch()"
       id="inputState" class="form-control">
        <option selected>Choose...</option>
        <option>materials</option>
        <option>technology</option>
        <option>management</option>
      </select>
      <p v-if="$v.form.inputsSourced.$error">
        <small v-if="!$v.form.inputsSourced.required" class="text-danger">This field is required</small>
      </p>
    </div>
    <!-- Multi select form -->
    <div class="form-group">
      <label for="inputAddress"
        >Which of these countries are the primary consumers of your
        products/services?</label
      >
      <select
        v-model="form.primaryConsumerCountries"
        id="inputState"
        @blur="$v.form.primaryConsumerCountries.$touch()"
        class="form-control"
      >
        <option selected>Choose...</option>
        <option>......</option>
        
      </select>
      <p v-if="$v.form.primaryConsumerCountries.$error">
          <small v-if="!$v.form.primaryConsumerCountries.required" class="text-danger">This field is required</small>
        </p>
    </div>

    <div class="form-group">
      <label for="project directly"
        >Will your products/services be exported?
      </label>
      &nbsp; &nbsp;
      <div class="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="customRadioInline1"
          name="tracking"
          class="custom-control-input"
          v-model="form.productExported"
        />
        <label class="custom-control-label" for="customRadioInline1">Yes</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="customRadioInline2"
          name="tracking"
          class="custom-control-input"
          v-model="form.productExported"
        />
        <label class="custom-control-label" for="customRadioInline2">No</label>
      </div>
    </div>
    <div class="form-group">
      <label for="inputAddress"
        >Which of these countries do you consider the primary destinations for
        export?</label
      >
      <select
        v-model="form.primaryExportDestinationCountries"
        id="inputState"
        @blur="$v.form.primaryExportDestinationCountries.$touch()"
        class="form-control"
      >
        <option selected>Choose...</option>
        <option>Ghana</option>
      </select>
      <p v-if="$v.form.primaryExportDestinationCountries.$error">
        <small v-if="!$v.form.primaryExportDestinationCountries.required" class="text-danger">
          This field is required
        </small>
      </p>
    </div>
    <div class="form-group">
      <label for="exampleFormControlTextarea1"
        >What is the route that your input goes through before arriving at your
        location?</label
      >
      <textarea
        v-model="form.routeInputGoesThrough"
        class="form-control"
        @blur="$v.form.routeInputGoesThrough.$touch()"
        id="exampleFormControlTextarea1"
        rows="3"
      ></textarea>
      <p v-if="$v.form.routeInputGoesThrough.$error">
        <small v-if="!$v.form.routeInputGoesThrough.required" class="text-danger">
          this field is required
        </small>
      </p>
    </div>
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      form: {
        countriesSourceInputsFromAndPercentage: {},
        inputsSourced: [],
        primaryConsumerCountries: [],
        productExported: true,
        primaryExportDestinationCountries: [],
        routeInputGoesThrough: "",
      },
    };
  },

  validations: {
    form: {
      countriesSourceInputsFromAndPercentage: { required },
      inputsSourced: { required },
      primaryConsumerCountries: { required },
      productExported: { required },
      primaryExportDestinationCountries: { required },
      routeInputGoesThrough: { required },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>