<template>
      <div class="col-md-10">
        <h2 class="pb-2">Connectivity</h2>
        <hr />
        <el-form
        style="width: 100%"
        :model="form"
        ref="form"
        status-icon
        label-position="left"
        >  
            <div class="row">
                <div class="col-12">
                    <el-form-item
                        prop="digitalActivity"
                        label="Please Indicate in what digital activity your project is">
                        <el-radio-group v-model="form.percentageOfDigitalCurrencyGap">
                            <el-radio  label="mobile"></el-radio>
                            <el-radio  label="satellite"></el-radio>
                            <el-radio label="fiber"></el-radio>
                            <el-radio label="financial"></el-radio>
                            <el-radio label="others"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>
            </div> 
            
            <div class="row">
                <div class="col-md-12">
                    <el-form-item label="What percentage of the digital connectivity gap will the project fill">
                        <el-input v-model="form.digitalActivity" type="text" placeholder="eg 10%"></el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="col-md-12">
                 <el-button @click="previous" type="primary" style="float:left">
                    previous
                </el-button>
                <el-button
                type="primary"
                @click="next('form')"
                >Next</el-button>
            </div>  
        </el-form>
      </div>
</template>

<script>
export default {
    name:'connectivity',
    data() {
        return { 
            form: {
                digitalActivity:'',
                percentageOfDigitalCurrencyGap:''
            },

        }
    },

    methods: {
       previous(){
             this.$emit('previousComponent', {
                 name:'TransportProject'
             })
        },
        next(form){
            this.$refs[form].validate( valid => {
                if( valid ) {
         // if validation successfully pass data to parent component and move to the next component
                    this.$emit('stepUpdate', {
                       next:'ValueAdditionManufacturing',
                       form: this.form
                    }) 

                } else {
                    
                }
            })
        }
    }
}
</script>

<style>

</style>