<template>
  <div class="col-md-10">
    <h2 class="pb-2">Non-tariff Barriers</h2>
    <hr />
    <el-form
      style="width: 100%"
      :rules="rules"
      :model="form"
      ref="form"
      label-position="left"
      status-icon
    >
      <div class="col-md-12">
        <el-form-item
          prop="incorporateDigitizationInCustomProcesses"
          label="Does the project allow for tracking of goods sent across borders?"
        >
          <el-radio-group
            v-model="form.incorporateDigitizationInCustomProcesses"
          >
            <el-radio value="true" label="Yes"></el-radio>
            <el-radio value="false" label="No"></el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <div class="col-md-12">
        <el-form-item
          prop="allowTrackingOfGoodsSendAcrossBorders"
          label="Does the project incorporate digitization features/capabilities in
          customs processes?"
        >
          <el-radio-group v-model="form.allowTrackingOfGoodsSendAcrossBorders">
            <el-radio value="true" label="Yes"></el-radio>
            <el-radio value="false" label="No"></el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <div class="col-md-12">
        <el-form-item
          prop="helpExtendBorderPostOpenTimes"
          label="Will the project help extend border post open times and create one
          stop border posts?"
        >
          <el-radio-group v-model="form.helpExtendBorderPostOpenTimes">
            <el-radio value="true" label="Yes"></el-radio>
            <el-radio value="false" label="No"></el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <div class="col-md-12">
        <el-form-item
          prop="allowForStandardsToBeRecognized"
          label="Does the project allow for standards/norms in one African country to
          be recognized in another?"
        >
          <el-radio-group v-model="form.allowForStandardsToBeRecognized">
            <el-radio value="true" label="Yes"></el-radio>
            <el-radio value="false" label="No"></el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="col-md-12">
        <el-form-item
          label="Which of these countries will have mutual recognition for the
          standards/norms created?"
          prop="countriesMutualRecognitionBeCreated"
        >
          <el-select
            :filterable="true"
            style="width: 100%"
            v-model="form.countriesMutualRecognitionBeCreated"
            prop="country"
            :multiple="true"
            placeholder="Select country"
          >
            <el-option
              v-for="(country, key) in countries"
              :key="key"
              :label="country.name"
              :value="country.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="col-md-12">
        <el-form-item
          prop="contributeToOvercomingChallenges"
          label="Does your project contribute to overcoming the challenges with
          non-tariff physical barriers?"
        >
          <el-radio-group v-model="form.contributeToOvercomingChallenges">
            <el-radio value="true" label="Yes"></el-radio>
            <el-radio value="false" label="No"></el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

       <el-button @click="previous" type="primary" style="float:left">
              previous
          </el-button>
          <el-button
          type="primary"
          @click="next('form')"
        >Next</el-button>
    </el-form>
  </div>
</template>

<script>
import VueCountrySelect from "vue-country-select";
import { mapGetters } from "vuex";
export default {
  components: {
    VueCountrySelect,
  },
  data() {
    return {
      isValid: false,
      form: {
        incorporateDigitizationInCustomProcesses: "",
        allowTrackingOfGoodsSendAcrossBorders: "",
        helpExtendBorderPostOpenTimes: "",
        allowForStandardsToBeRecognized: "",
        countriesMutualRecognitionBeCreated: [],
        contributeToOvercomingChallenges: "",
      },
      rules: {
        incorporateDigitizationInCustomProcesses: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
        allowTrackingOfGoodsSendAcrossBorders: [
          {
           required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],

        helpExtendBorderPostOpenTimes: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
        allowForStandardsToBeRecognized: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
        countriesMutualRecognitionBeCreated: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
        contributeToOvercomingChallenges: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["countries"]),
  },
  created() {
    this.$store.dispatch("fetchCountries");
  },
  methods: {
        previous(){
             this.$emit('previousComponent', {
                 name:'Power'
             })
        },
        next(form){
            this.$refs[form].validate( valid => {
                if( valid ) {
         // if validation successfully pass data to parent component and move to the next component
                    this.$emit('stepUpdate', {
                       next:'ownershipStructure',
                       form: this.form
                    }) 
                } else {
                    
                }
            })
        }
  }
};
</script>

<style lang="scss" scoped>
</style>