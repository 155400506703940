<template>
  <form @input="emitFormData" class="col-md-10">
    <h2 class="pb-2">Basic Profile</h2>
    <hr />
    <h5>Project Organization/Company</h5>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputName">Name</label>
        <input
          type="name"
          class="form-control"
          id="inputName"
          placeholder="Name"
          @blur="$v.form.organizationName.$touch()"
          v-model="form.organizationName"
        />
        <p v-if="$v.form.organizationName.$error">
          <small v-if="!$v.form.organizationName.required" class="text-danger">
            This field is required
          </small>
        </p>
      </div>

      <div class="form-group col-md-6">
        <label for="inputEmail">Email</label>
        <input
          type="email"
          class="form-control"
          id="inputEmail"
          placeholder="Email"
          v-model="form.emailAddress"
          @blur="$v.form.emailAddress.$touch()"
        />
        <p v-if="$v.form.emailAddress.$error">
          <small v-if="!$v.form.emailAddress.required" class="text-danger">
            This field is required
          </small>
          <small v-if="!$v.form.emailAddress.isUnique" class="text-danger">
            please enter valid email address here
          </small>
        </p>
      </div>
    </div>
    <div class="form-group">
      <label for="telephonenumber">Phone Number</label>
      <vue-tel-input
        id="telephonenumber"
        @blur="$v.form.telephoneNumber.$touch()"
        v-model="form.telephoneNumber"
      ></vue-tel-input>
      <p v-if="$v.form.telephoneNumber.$error">
        <small v-if="!$v.form.telephoneNumber.required" class="text-danger">
          This field is required
        </small>
      </p>
    </div>

    <h5>Contact Person</h5>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputName1">Name</label>
        <input
          type="text"
          class="form-control"
          placeholder="Name"
          id="inputName1"
          v-model="form.nameOfContactPerson"
          @blur="$v.form.nameOfContactPerson.$touch()"
        />
        <p v-if="$v.form.nameOfContactPerson.$error">
          <small
            v-if="!$v.form.nameOfContactPerson.required"
            class="text-danger"
          >
            This field is required
          </small>
        </p>
      </div>

      <div class="form-group col-md-6">
        <label for="inputEmail">Email</label>
        <input
          class="form-control"
          id="emailAddressOfContactPerson"
          placeholder="Email"
          v-model="form.emailAddressOfContactPerson"
          @blur="$v.form.emailAddressOfContactPerson.$touch()"
        />
        <p v-if="$v.form.emailAddressOfContactPerson.$error">
          <small
            v-if="!$v.form.emailAddressOfContactPerson.required"
            class="text-danger"
            >This field is required</small
          >
          <small
            v-if="!$v.form.emailAddressOfContactPerson.isUnique"
            class="text-danger"
          >
            please enter valid email address here
          </small>
        </p>
      </div>
    </div>

    <div class="form-group">
      <label for="telephone">Phone Number</label>
      <vue-tel-input
        id="telephone"
        @blur="$v.form.telephoneNumberOfContactPerson.$touch()"
        v-model="form.telephoneNumberOfContactPerson"
        class="form-control"
      ></vue-tel-input>
      <p v-if="$v.form.telephoneNumberOfContactPerson.$error">
        <small
          v-if="!$v.form.telephoneNumberOfContactPerson.required"
          class="text-danger"
        >
          This field is required
        </small>
      </p>
    </div>

    <div class="form-group">
      <label for="inputState"
        >In what sector is your project/proposed project
        <span class="text-info">(Primary, secondary, tertiary)</span></label
      >
      <select
        @blur="$v.form.sector.$touch()"
        v-model="form.sector"
        id="inputState"
        class="form-control"
      >
        <option selected value>Choose...</option>
        <option value="Primary">Primary</option>
        <option value="Secondary">Secondary</option>
        <option value="Tertiary">Tertiary</option>
      </select>
      <p v-if="$v.form.sector.$error">
        <small v-if="!$v.form.sector.required" class="text-danger">
          This field is required
        </small>
      </p>
    </div>

    <div class="form-group">
      <label for="industry"
        >Which of the following industry(ies) will your project be classified
        under?</label
      >
      <select
        v-model="form.industry"
        @blur="$v.form.industry.$touch()"
        id="industry"
        @change="emitFormData"
        class="form-control"
      >
        <option selected value="">Choose...</option>
        <option value="Construction">Construction</option>
        <option value="Oil & Gas">Oil & Gas</option>
        <option value="Agriculture, Forestry & Fishing">
          Agriculture, Forestry & Fishing
        </option>
        <option value="Agribusiness">Agribusiness</option>
        <option value="Automotive">Automotive</option>
        <option value="Manufacturing">Manufacturing</option>
        <option value="Hospitality">Hospitality</option>
        <option value="Media & Entertainment">Media & Entertainment</option>
        <option value="Tourism & Recreation">Tourism & Recreation</option>
        <option value="Arts & Culture">Arts & Culture</option>
        <option value="Minning & Quarrying">Minning & Quarrying</option>
        <option value="Transportation & Warehousing">
          Transportation & Warehousing
        </option>
        <option value="Financial & Insurance Services">
          Financial & Insurance Services
        </option>
        <option value="Financial Technology (Fintech)">
          Financial Technology (Fintech)
        </option>
        <option value="Information & Communication Technology">
          Information & Communication Technology
        </option>
        <option value="Real Estate">Real Estate</option>
        <option value="Electricity & Power Generation">
          Electricity & Power Generation
        </option>
        <option value="Renewable Energy">Renewable Energy</option>
        <option value="Consumer Durables & Apparels">
          Consumer Durables & Apparels
        </option>
        <option value="Wholesale & Retail Trade">
          Wholesale & Retail Trade
        </option>
        <option value="Healthcare Equipment & Services">
          Healthcare Equipment & Services
        </option>
        <option value="Pharmaceutical">Pharmaceutical</option>
        <option value="Education">Education</option>
        <option value="Utilities">Utilities</option>
        <option value="Food & Beverage">Food & Beverage</option>
        <option value="other">other</option>
      </select>
      <p v-if="$v.form.industry.$error">
        <small v-if="!$v.form.industry.required" class="text-danger">
          This field is required
        </small>
      </p>
    </div>
    <div class="form-group">
      <label for="country">What is your country of incorporation?</label>
      <country-select
        @blur="$v.form.country.$touch()"
        class="form-control"
        v-model="form.country"
        :country="form.country"
        topCountry="US"
        @change="emitFormData"
      />
      <p v-if="$v.form.country.$error">
        <small v-if="!$v.form.country.required" class="text-danger">
          This field is required
        </small>
      </p>
    </div>
    <div class="form-group">
      <label for="companyaffiliated"
        >Is your company affiliated with any business association?</label
      >
      &nbsp;
      <span
        ><input
          v-model="form.companyAffiliatedWithBusinessAssociation"
          type="radio"
          value="true"
          name="company"
          id="companyaffiliated"
        />
        Yes
        <input
          v-model="form.companyAffiliatedWithBusinessAssociation"
          type="radio"
          value="false"
          name="company"
          id="companyaffiliated"
        />
        No</span
      >
      <div
        class="form-group"
        v-if="form.companyAffiliatedWithBusinessAssociation === 'true'"
      >
        <label for="exampleFormControlTextarea1"
          >If yes please provide details.</label
        >
        <textarea
          @blur="
            $v.form.companyAffiliatedWithBusinessAssociationDetails.$touch()
          "
          class="form-control"
          rows="3"
          v-model="form.companyAffiliatedWithBusinessAssociationDetails"
          placeholder="company affiliation  details"
        ></textarea>
        <p
          v-if="$v.form.companyAffiliatedWithBusinessAssociationDetails.$error"
        >
          <small
            v-if="
              !$v.form.companyAffiliatedWithBusinessAssociationDetails.required
            "
            class="text-danger"
          >
            This field is required
          </small>
        </p>
      </div>
    </div>

    <div class="form-group">
      <label for="companyType"
        >Indicate the type of Company
        <span class="text-info"
          >(SME, Large Company, Multi-national, etc.)</span
        ></label
      >
      <select
        @blur="$v.form.companyType.$touch()"
        v-model="form.companyType"
        id="companyType"
        class="form-control"
        @change="emitFormData"
      >
        <option selected value>Choose...</option>
        <option value="SME">SME</option>
        <option value="Large Company">Large Company</option>
        <option value="Multi-national">Multi-national</option>
        <option value="Other">Other</option>
      </select>
      <p v-if="$v.form.companyType.$error">
        <small v-if="!$v.form.companyType.required" class="text-danger">
          This field is required
        </small>
      </p>
    </div>

    <div class="form-group">
      <label for="afcftaNumber">Kindly indicate your AfCFTA number</label>
      <input
        type="number"
        class="form-control"
        id="afcftaNumber"
        placeholder="AfCFTA number"
        v-model="form.afcftaNumber"
        @blur="$v.form.afcftaNumber.$touch()"
      />
      <p v-if="$v.form.afcftaNumber.$error">
        <small v-if="!$v.form.afcftaNumber.required" class="text-danger">
          This field is required
        </small>
      </p>
    </div>
  </form>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { VueTelInput } from "vue-tel-input";
import VueCountrySelect from "vue-country-select";

export default {
  components: {
    VueTelInput,
    VueCountrySelect,
  },
  data() {
    return {
      form: {
        organizationName: "",
        emailAddress: "",
        telephoneNumber: "",
        nameOfContactPerson: "",
        emailAddressOfContactPerson: "",
        telephoneNumberOfContactPerson: "",
        sector: "",
        industry: "",
        country: "",
        companyAffiliatedWithBusinessAssociation: false,
        companyAffiliatedWithBusinessAssociationDetails: "",
        companyType: "",
        afcftaNumber: "",
      },
    };
  },

  validations: {
    form: {
      organizationName: { required },
      emailAddress: {
        email,
        required,
        isUnique(value) {
          if (value == "") return true;

          const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(emailRegex.test(value));
            }, 500);
          });
        },
      },
      telephoneNumber: { required },
      nameOfContactPerson: { required },
      emailAddressOfContactPerson: {
        email,
        required,
        isUnique(value) {
          if (value == "") return true;

          const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(emailRegex.test(value));
            }, 500);
            });
        },
      },
      telephoneNumberOfContactPerson: { required },
      sector: { required },
      industry: { required },
      country: { required },
      afcftaNumber: { required },
      companyType: { required },
      companyAffiliatedWithBusinessAssociation: { required },
      companyAffiliatedWithBusinessAssociationDetails: { required },


    },
  },
  methods: {
    emitFormData() {
      // this.$emit("stepUpdated", {
      //   data: this.form,
      //   valid: !this.$v.$invalid,
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>