<template>
  <div class="col-md-10">
    <h2 class="pb-2">Transportation Projects</h2>
    <hr />
    <el-form
      style="width: 100%"
      :model="form"
      :rules="rules"
      ref="form"
      label-position="left"
      status-icon
    >
      <div class="col-md-12">
        <el-form-item
          :filterable="true"
          prop="modeOfTransportation"
          label="Indicate the mode of transportation that the project can be categorized
        under"
        >
          <el-select
            style="width: 100%"
            v-model="form.modeOfTransportation"
            placeholder="select the mode of transportation"
          >
            <el-option value="Rail" label="Rail">Rail</el-option>
            <el-option value="Road" label="Road">Road</el-option>
            <el-option value="Air" label="Air">Air</el-option>
            <el-option value="Pipeline" label="Pipeline">Pipeline</el-option>
            <el-option value="Maritime" label="Maritime">Maritime</el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="col-md-12">
        <el-form-item
          :filterable="true"
          prop="expectedCarriage"
          label="what is the expected carriage?"
        >
          <el-select
            style="width: 100%"
            v-model="form.expectedCarriage"
            placeholder="select the expected carriage"
          >
            <el-option value="Cargo" label="Cargo">Cargo</el-option>
            <el-option value="Passengerdropdown" label="Passenger dropdown"
              >Passenger dropdown</el-option
            >
          </el-select>
        </el-form-item>
      </div>

      <div class="col-md-12">
        <el-form-item
          prop="serviceType"
          label="Indicate whether the service is domestic or international"
        >
          <el-radio-group v-model="form.serviceType">
            <el-radio value="domestic" label="domestic"></el-radio>
            <el-radio value="international" label="international"></el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <div  class="col-md-12" v-if="form.serviceType === 'international'">
        <el-form-item
          label="If International, what countries are being connected."
          prop="country"
        >
          <el-select
            :filterable="true"
            :multiple="true"
            style="width: 100%"
            v-model="form.countriesConnected"
            prop="country"
            placeholder="Select country"
          >
            <el-option
              v-for="(country, key) in countries"
              :key="key"
              :label="country.name"
              :value="country.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="col-md-12">
        <el-form-item
          label="Which of these cities/countries does your project connects to?"
          prop="citiesOrCountriesConnected"
        >
          <el-select
            :filterable="true"
            :multiple="true"
            style="width: 100%"
            v-model="form.citiesOrCountriesConnected"
            prop="country"
            placeholder="Select country"
          >
            <el-option
              v-for="(country, key) in countries"
              :key="key"
              :label="country.name"
              :value="country.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="col-md-12">
        <el-form-item
          :filterable="true"
          prop="digitalActivity"
          label="Please Indicate in what digital activity your project is"
        >
          <el-select
            style="width: 100%"
            v-model="form.digitalActivity"
            placeholder="select digital activity"
          >
            <el-option value="mobile" label="mobile">mobile</el-option>
            <el-option value="satellite" label="satellite"
              >satellite</el-option
            >
            <el-option value="fiber" label="fiber">fiber</el-option>
            <el-option value="financial" label="financial">financial</el-option>
            <el-option value="others" label="others">others</el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="col-md-12">
        <el-form-item
          prop="percentageOfDigitalCurrencyGap"
          label="What percentage of the digital connectivity gap will the project
        fill?"
        >
          <el-input
            type="number"
            placeholder="indicate  digital connectivity gap will the project"
            v-model="form.percentageOfDigitalCurrencyGap"
          ></el-input>
        </el-form-item>
      </div>
       <el-button @click="previous" type="primary" style="float:left">
                previous
            </el-button>
            <el-button
            type="primary"
            @click="next('form')"
            >Next</el-button>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueCountrySelect from "vue-country-select";
export default {
  components: {
    VueCountrySelect,
  },
  data() {
    return {
      isValid: false,
      form: {
        modeOfTransportation: "",
        expectedCarriage: "",
        serviceType: "",
        countriesConnected: [],
        citiesOrCountriesConnected: [],
        digitalActivity: "",
        percentageOfDigitalCurrencyGap: "",
      },
      rules: {
        modeOfTransportation: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
        expectedCarriage: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
        serviceType: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
        citiesOrCountriesConnected: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
        digitalActivity: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
        percentageOfDigitalCurrencyGap: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["countries"]),
  },
  created() {
    this.$store.dispatch("fetchCountries");
  },
  methods: {
    previous(){
             this.$emit('previousComponent', {
                 name:'BasicDetails'
             })
        },
    next(form) {
      this.$refs[form].validate( valid => {
            if( valid ) {
                 // if validation successfully pass data to parent component and move to the next component
                    this.$emit('stepUpdate', {
                       next:'ValueAdditionManufacturing',
                       form: this.form}
                    ) 
            } else {
                
            }
        })
    }
  }
};
</script>
