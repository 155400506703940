<template>
  <div class="col-md-10">
    <h2 class="pb-2">Power</h2>
    <hr />

    <el-form
      style="width: 100%"
      :model="form"
      :rules="rules"
      ref="form"
      status-icon
      label-position="left"
    >
      <div class="col-md-12">
        <el-form-item
          :filterable="true"
          prop="subsectorOfPower"
          label="Indicate the subsector of power"
        >
          <el-select
            style="width: 100%"
            v-model="form.subsectorOfPower"
            placeholder="select Subsector Of Power"
          >
            <el-option value="Generation" label="Generation"
              >Generation</el-option
            >   
            <el-option value="Transmission" label="Transmission"
              >Transmission</el-option
            >
            <el-option value="distribution" label="distribution"
              >distribution</el-option
            >
            <el-option value="Sales" label="Sales">Sales</el-option>
            <el-option value="Others" label="Others">Others</el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="col-md-12">
        <el-form-item
          prop="sourceOfPowerGeneration"
          label="Indicate source of power generation"
        >
          <el-radio-group v-model="form.sourceOfPowerGeneration">
            <el-radio value="Renewable" label="Renewable"></el-radio>
            <el-radio value="Non-renewable" label="Non-renewable"></el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <div
        class="col-md-12"
        v-if="form.sourceOfPowerGeneration === 'Renewable'"
      >
        <el-form-item
          :filterable="true"
          prop="sourceOfEnergy"
          label="If renewable, indicate the source of energy"
        >
          <el-select
            style="width: 100%"
            v-model="form.sourceOfEnergy"
            placeholder="select source of energy"
          >
            <el-option value="Hydro" label="Hydro">Hydro</el-option>
            <el-option value="Wind" label="Wind">Wind</el-option>
            <el-option value="Solar" label="Solar">Solar</el-option>
          </el-select>
        </el-form-item>
      </div>

      <div
        class="col-md-12"
        v-if="form.sourceOfPowerGeneration === 'Non-renewable'"
      >
        <el-form-item
          :filterable="true"
          prop="sourceOfEnergy"
          label="If Non-renewable, indicate the source of energy"
        >
          <el-select
            style="width: 100%"
            v-model="form.sourceOfEnergy"
            placeholder="select source of energy"
          >
            <el-option value="Oil" label="Oil">Oil</el-option>
            <el-option value="Coal" label="Coal">Coal</el-option>
            <el-option value="energy" label="energy">energy</el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="col-md-12">
        <el-form-item
          :filterable="true"
          prop="categoryOfConsumersPowerWillServe"
          label="Which of the following categories of consumers will your power serve
          ?"
        >
          <el-select
            style="width: 100%"
            v-model="form.categoryOfConsumersPowerWillServe"
            placeholder="select source of energy"
          >
            <el-option
              value="economiczones"
              label="industrial and economic zones"
              >industrial and economic zones</el-option
            >
            <el-option value="otherindustries" label="other industries"
              >other industries</el-option
            >
            <el-option
              value="education"
              label="educational and other commercial"
              >educational and other commercial</el-option
            >
          </el-select>
        </el-form-item>
      </div>

      <div class="col-md-12">
        <el-form-item
          label="Which industrial/economic zones does your project connect power
          to?"
          prop="industrialZoneConnectPowerTo"
        >
          <el-input type="text" placeholder="industrial Zone Connect Power To" v-model="form.industrialZoneConnectPowerTo"></el-input>
        </el-form-item>
      </div>

       <div class="col-md-12">
        <el-form-item
          label="Indicative tariff range (kw/hr)
          "
          prop="tarrifRange"
        >
       
          <el-select
            style="width: 100%"
            v-model="form.tarrifRange"
            :filterable="true"
            placeholder="Select tariff range"
          >
            <el-option
              value="1– 5cent"
              label="1– 5cent"
              >1– 5cent</el-option
            >
            <el-option value="6-10" label="6-10"
              >6-10</el-option
            >
            <el-option
              value="11-15"
              label="11-15"
              >11-15</el-option>
              <el-option
              value="16-20"
              label="16-20"
              >16-20</el-option>
            <el-option
              value="21-25"
              label="21-25"
              >21-25</el-option>
              <el-option
              value="26-30"
              label="26-30"
              >26-30</el-option>
              <el-option
              value="30-35"
              label="30-35"
              >30-35</el-option>
              <el-option
              value="more than 35"
              label="more than 35"
              >more than 35</el-option>
            
          </el-select>
        </el-form-item>
      </div>

         <el-button @click="previous" type="primary" style="float:left">
            previous
        </el-button>
        <el-button
        type="primary"
        @click="next('form')"
        >Next</el-button>

    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
        isValid: false,
      form: {
        subsectorOfPower: "",
        sourceOfPowerGeneration: "",
        sourceOfEnergy: "",
        categoryOfConsumersPowerWillServe: "",
        industrialZoneConnectPowerTo: "",
        tarrifRange: "",
      },
      rules: {
         sourceOfEnergy: [
            {
              required: true,
              message: 'Please this field is required',
              trigger: 'change'
            }
          ],
        subsectorOfPower: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
        sourceOfPowerGeneration: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
        categoryOfConsumersPowerWillServe: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
        industrialZoneConnectPowerTo: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
        tarrifRange: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
   previous(){
             this.$emit('previousComponent', {
                 name:'ValueAdditionManufacturing'
             })
        },
        next(form){
            this.$refs[form].validate( valid => {
                if( valid ) {
         // if validation successfully pass data to parent component and move to the next component
                    this.$emit('stepUpdate', {
                       next:'NonTarifficBar',
                       form: this.form
                    }) 

                } else {
                    
                }
            })
        }
  }
};
</script>

<style lang="scss" scoped>
</style>