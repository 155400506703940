<template>
  <div class="col-md-10">
    <h2 class="pb-2">Employment Creation</h2>
    <hr />
    <div class="form-group">
      <label for="employees"
        >How many employees will your project engage, overall?</label
      >
      <input
        type="number"
        class="form-control"
        placeholder="How many employees will your project engage, overall?"
      />
    </div>
    <div class="form-group">
      <label for="non-direct employees"
        >How many non-direct employees will your project engage
        downstream/across the integrated chain of the project</label
      >
      <input
        type="text"
        class="form-control"
        placeholder="How many non-direct employees will your project engage downstream/across the integrated chain of the project"
      />
    </div>
    <div class="form-group">
      <label for="estimated market size"
        >What is the estimated market size that you expect your
        project/investment to address?</label
      >
      <input
        type="number"
        class="form-control"
        placeholder="estimated market size"
      />
    </div>
    <div class="form-group">
      <label for="employee profit or shares scheme?"
        >Does the company have an employee profit or shares scheme?
      </label>
      &nbsp; &nbsp;
      <div class="custom-control custom-control-inline">
        <div class="radio">
          <label><input type="radio" name="optradio" /> Yes</label>
        </div>
      </div>
      <div class="custom-control custom-control-inline">
        <div class="radio">
          <label><input type="radio" name="optradio" /> No</label>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="project directly"
        >Is the company listed or intend to list on the stock exchange?
      </label>
      &nbsp; &nbsp;
      <div class="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="customRadioInline1"
          name="tracking"
          class="custom-control-input"
        />
        <label class="custom-control-label" for="customRadioInline1"
          >listed</label
        >
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="customRadioInline2"
          name="tracking"
          class="custom-control-input"
        />
        <label class="custom-control-label" for="customRadioInline2"
          >intend to list</label
        >
      </div>
    </div>
    <div class="form-group">
      <label for="employees"
        >How is the value chain integrated into the existing value chains in the
        country/continent?</label
      >
      <input
        type="number"
        class="form-control"
        placeholder="How is the value chain integrated into the existing value chains in the country/continent?"
      />
    </div>
    <div class="form-group">
      <label for="project directly"
        >Will this project depend on proprietary technology or licensing from
        other entities?
      </label>
      &nbsp; &nbsp;
      <div class="custom-control custom-control-inline">
        <div class="radio">
          <label><input type="radio" name="optradio" /> Yes</label>
        </div>
      </div>
      <div class="custom-control custom-control-inline">
        <div class="radio">
          <label><input type="radio" name="optradio" /> No</label>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="raw material"
        >If yes, which of these countries does the ownership originate
        from?</label
      >
      <select class="form-control">
        <option value="Ghana">Ghana</option>
        <option value="Ghana">Togo</option>
        <option value="Nigerial">Nigeria</option>
      </select>
    </div>
    <div class="form-group">
      <label for="employees"
        >What is the estimated percentage of dividends/profits to African
        shareholders or equity contributors from Africa?</label
      >
      <input
        type="number"
        class="form-control"
        placeholder="What is the estimated percentage of dividends/profits to African shareholders or equity
contributors from Africa?"
      />
    </div>
    <div class="form-group">
      <label for="employees"
        >What is the estimated total spend of the project in Africa?</label
      >
      <input
        type="number"
        class="form-control"
        placeholder="What is the estimated total spend of the project in Africa?"
      />
    </div>
    <div class="form-group">
      <label for="project directly"
        >Does your project have social/environmental outcome targets?
      </label>
      &nbsp; &nbsp;
      <div class="custom-control custom-radio custom-control-inline">
        <label><input type="radio" name="environmental" /> Yes</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <label><input type="radio" name="environmental" /> No</label>
      </div>
    </div>
    <div class="form-row">
      <label for=""
        >Please complete below table with the projected financials of the
        project for the first 5 years</label
      >
      <div class="form-group text-center col-md-6">
        <label for="share-holder"><b>Revenue</b></label>
      </div>
      <div class="form-group col-md-6">
        <input
          type="text"
          class="form-control"
          placeholder="Enter percentage here..."
        />
      </div>
      <div class="form-group text-center col-md-6">
        <label for="share-holder"><b>Profit</b></label>
      </div>
      <div class="form-group col-md-6">
        <input
          type="text"
          class="form-control"
          placeholder="Enter percentage here..."
        />
      </div>
      <div class="form-group text-center col-md-6">
        <label for="share-holder"><b>Assett</b></label>
      </div>
      <div class="form-group col-md-6">
        <input
          type="text"
          class="form-control"
          placeholder="Enter percentage here..."
        />
      </div>
      <div class="form-group text-center col-md-6">
        <label for="share-holder"><b>Loan</b></label>
      </div>
      <div class="form-group col-md-6">
        <input
          type="text"
          class="form-control"
          placeholder="Enter percentage here..."
        />
      </div>
      <div class="form-group text-center col-md-6">
        <label for="share-holder"><b>Equity</b></label>
      </div>
      <div class="form-group col-md-6">
        <input
          type="text"
          class="form-control"
          placeholder="Enter percentage here..."
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData:{
        numbenumberOfEmployeesToEngager:"",
        numberOfNonDirectEmployees:"",
        estimatedMarketSize:"",
        employeeProfitOrSharesScheme:true,
        companyListedOnStockExchange:true
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>