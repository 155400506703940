<template>
  <div class="col-md-10">
    <h2 class="pb-2">BASIC DETAILS OF PROJECT</h2>
    <hr />
    <form @input="emitFormData">
      <div class="form-group">
        <label for="project-category"
          >Indicate the project category (Infrastructure, Service, Product,
          Commodities, etc.)</label
        >
        <input
          name="project-category"
          @blur="$v.form.project_category.$touch()"
          v-model="form.project_category"
          type="text"
          class="form-control"
          placeholder="Indicate project category"
        />
        <p v-if="$v.form.project_category.$error">
          <small v-if="!$v.form.project_category.required" class="text-danger">
            This field is required
          </small>
        </p>
      </div>

      <div class="form-group">
        <label for="project-date"
          >Please indicate project commencement date</label
        >
        <input
          type="date"
          @blur="$v.form.project_commenced_date.$touch()"
          v-model="form.project_commenced_date"
          name="project-date"
          class="form-control"
        />
        <p v-if="$v.form.project_commenced_date.$error">
          <small
            v-if="!$v.form.project_commenced_date.required"
            class="text-danger"
          >
            This field is required
          </small>
        </p>
      </div>

      <div class="form-group">
        <label for="location"
          >What is the current or proposed project location?</label
        >
        <input
          type="text"
          @blur="$v.form.project_location.$touch()"
          v-model="form.project_location"
          name="location"
          class="form-control"
          placeholder="eg, Ghana"
        />
        <p v-if="$v.form.project_location.$error">
          <small v-if="!$v.form.project_location.required" class="text-danger">
            This field is required
          </small>
        </p>
      </div>

      <div class="form-group">
        <label for="budget"
          >What is the size of your project in terms of funding requirement,
          budget or turnover? (provide range)</label
        >
        <input
          type="text"
          @blur="$v.form.project_funding.$touch()"
          v-model="form.project_funding"
          class="form-control"
          name="budget"
          placeholder="eg (GH₵: 20,0000 - 30,000)"
        />
        <p v-if="$v.form.project_funding.$error">
          <small v-if="!$v.form.project_funding.required" class="text-danger">
            This field is required
          </small>
        </p>
      </div>

      <div class="form-group">
        <label for="#exampleFormControlSelect1"
          >Please indicate which of the following sectors best describes your
          project in relation to the AFCTA?</label
        >
        <select
          v-model="form.project_sections"
          class="form-control"
          @blur="$v.form.project_sections.$touch()"
          id="exampleFormControlSelect1"
          required
        >
          <option value selected>Select Sector</option>
          <option>Agribusiness</option>
          <option value="Transport Corridors">Transport Corridors</option>
          <option value="Agroprocessing">Agroprocessing</option>
          <option value="Professional Services">Professional Services</option>
          <option value="Health Systems Integration ">
            Health Systems Integration
          </option>
          <option value="General Manufacturing ">General Manufacturing</option>
          <option value="Trade & Logistics">Trade & Logistics</option>
          <option value="Hospitality & Tourism">Hospitality & Tourism</option>
          <option value="Education & Skills">Education & Skills</option>
          <option value="Road & Rail Network Infrastructure">
            Road & Rail Network Infrastructure
          </option>
          <option value="Energy Grids">Energy Grids</option>
          <option value="Water Management">Water Management</option>
          <option value="Clean Energy">Clean Energy</option>
          <option value="Ports & Terminals">Ports & Terminals</option>
          <option value="E-commerce">E-commerce</option>
          <option value="E-government">E-government</option>
          <option value="Fashion Value Chains">Fashion Value Chains</option>
          <option value="Creative Arts">Creative Arts</option>
          <option value="Entertainment Production Hubs">
            Entertainment Production Hubs
          </option>
        </select>
        <p v-if="$v.form.project_sections.$error">
          <small v-if="!$v.form.project_sections.required" class="text-danger">
            This field is required
          </small>
        </p>
      </div>

      <div class="form-group">
        Will your project be carried out in phases
        <div class="form-check">
          <input
            v-model="form.carriedOutInPhases"
            value="true"
            class="form-check-input"
            type="radio"
            name="phases"
            id="carriedout1"
          />
          <label class="form-check-label" for="carriedout1"> Yes </label>
        </div>
        <div class="form-check">
          <input
            v-model="form.carriedOutInPhases"
            value="false"
            class="form-check-input"
            type="radio"
            name="phases"
            id="carriedout1"
            checked
          />
          <label class="form-check-label" for="carriedout1"> No </label>
        </div>
      </div>

      <div class="orw" v-if="form.carriedOutInPhases">
        <div class="col-md-12">
          <p>what is the completion timeline for each phase</p>
        </div>
        <div class="col-md-12">
          {{ phases }}
          <div class="form-row" v-for="(phase, key) in phases" :key="key">
            <div class="col-5">
              <input type="text" placeholder="Phase" v-model="phase.phase" />
            </div>
            <div class="col-5">
              <input
                type="text"
                placeholder="completion timeline"
                v-model="phase.timline"
              />
            </div>
            <div class="col-2">
              <button
                type="button"
                v-if="phases.length !== 1"
                class="btn btn-danger my-1 btn-sm"
                @click="deletePhase(key)"
              >
                delete
              </button>
            </div>
            <div class="div">
              <button
                v-if="key == phases.length - 1"
                type="button"
                class="btn my-1 btn-success btn-sm"
                @click="addPhase()"
              >
                Add Phase
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="#exampleFormControlSelect1"
          >What is the overall expected completion date</label
        >
        <select
          v-model="form.Project_completion_date"
          class="form-control"
          id="exampleFormControlSelect1"
          @blur="$v.form.Project_completion_date.$touch()"
          required
        >
          <option value selected>Select completion date</option>
          <option value="2020 - 2025">2020 - 2025</option>
          <option value="2025 - 2030">2020 - 2025</option>
          <option value="beyond 2030">Beyond 2030</option>
        </select>
        <p v-if="$v.form.Project_completion_date.$error">
          <small
            v-if="!$v.form.Project_completion_date.required"
            class="text-danger"
          >
            This field is required
          </small>
        </p>
      </div>

      <div class="form-group">
        Is there an off taker/long term identified buyer/ purchase contract for
        your project?
        <div class="form-check">
          <input
            v-model="form.is_project_contractor"
            value="true"
            class="form-check-input"
            type="radio"
            name="contractor"
            id="flexRadioDefault1"
          />
          <label class="form-check-label" for="flexRadioDefault1"> Yes </label>
        </div>
        <div class="form-check">
          <input
            v-model="form.is_project_contractor"
            value="false"
            class="form-check-input"
            type="radio"
            name="contractor"
            id="flexRadioDefault2"
            checked
          />
          <label class="form-check-label" for="flexRadioDefault2"> No </label>
        </div>
      </div>

      <div class="form-group" v-if="form.is_project_contractor === 'true'">
        <label for="#exampleFormControlSelect1"
          >Select buyer / purchase contractor</label
        >
        <select
          v-model="form.project_contractor"
          class="form-control"
          id="exampleFormControlSelect1"
          required
        >
          <option value selected>Select buyer / purchase contractor</option>
          <option value="government">Government</option>
          <option value="manufactures">Manufactures</option>
          <option value="households">Households</option>
          <option value="other industry">Other Industry</option>
        </select>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      phases: [
        {
          phase: "",
          timeline: "",
        },
      ],
      form: {
        project_category: "",
        project_commenced_date: "",
        project_location: "",
        project_funding: "",
        project_sections: "",
        Project_completion_date: "",
        is_project_contractor: false,
        project_contractor: "",
        carriedOutInPhases: false,
        completionTimeForEachPhase: {},
      },
    };
  },
  validations: {
    form: {
      project_category: { required },
      project_commenced_date: { required },
      project_location: { required },
      project_funding: { required },
      project_sections: { required },
      project_sections: { required },
      Project_completion_date: { required },
      is_project_contractor: { required },
      project_contractor: { required },
      carriedOutInPhases: { required },
    },
  },
  methods: {
    addPhase() {
      this.phases.push({
        phase: "",
        timeline: "",
      });
    },
    deletePhase(key) {
      this.phases.splice(key, 1);
    },
    handleTimeline(key) {
      this.form.completionTimeForEachPhase[this.phases[key].phase] = this.phases[key].timeline
    },
    emitFormData() {
      this.$emit("stepUpdated", {
        data: this.form,
        valid: !this.$v.$invalid,
      });
    },
  },
};
</script>

<style>
</style>