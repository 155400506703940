<template>
  <div class="col-md-10 pl-5">
    <h2 class="pb-2">Power</h2>
    <hr />
    <form @input="emitFormData">
      <div class="form-group">
        <label for="raw material">Indicate the subsector of power</label>
        <select
          @blur="$v.form.subsectorOfPower.$touch()"
          v-model="form.subsectorOfPower"
          class="form-control"
        >
          <option value="generation">Generation</option>
          <option value="transmission">Transmission</option>
          <option value="distribution">distribution</option>
          <option value="sales">Sales</option>
          <option value="others">Others</option>
        </select>
        <p v-if="$v.form.subsectorOfPower.$error">
          <small v-if="!$v.form.subsectorOfPower.required" class="text-danger">
            This field is required
          </small>
        </p>
      </div>
      <div class="form-group">
        <label for="project directly"
          >Indicate source of power generation</label
        >
        &nbsp; &nbsp;
        <div class="custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            id="customRadioInline1"
            name="customRadioInline1"
            class="custom-control-input"
            v-model="form.sourceOfPowerGeneration"
          />
          <label class="custom-control-label" for="customRadioInline1"
            >Renewable</label
          >
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            id="customRadioInline2"
            name="customRadioInline1"
            class="custom-control-input"
            v-model="form.sourceOfPowerGeneration"
          />
          <label class="custom-control-label" for="customRadioInline2"
            >Non-renewable</label
          >
        </div>
      </div>
      <div class="form-group">
        <label for="raw material"
          >If renewable, indicate the source of energy</label
        >
        <select v-model="form.sourceOfEnergy" class="form-control">
          <option value=" Hydro">Hydro</option>
          <option value="Wind">Wind</option>
          <option value="Solar">Solar</option>
        </select>
      </div>
      <div class="form-group">
        <label for="raw material"
          >If non-renewable, indicate the source of energy</label
        >
        <select v-model="form.sourceOfEnergy" class="form-control">
          <option value="Oil">Oil</option>
          <option value="coal">Coal</option>
          <option value="Nuclear">energy</option>
        </select>
      </div>
      <div class="form-group">
        <label for="raw material"
          >Which of the following categories of consumers will your power serve
          ?</label
        >
        <select
          v-model="form.categoryOfConsumersPowerWillServe"
          @blur="$v.form.categoryOfConsumersPowerWillServe.$touch()"
          class="form-control"
        >
          <option>industrial and economic zones</option>
          <option>other industries</option>
          <option>educational and other commercial</option>
          <option>household consumers</option>
        </select>
        <p v-if="$v.form.categoryOfConsumersPowerWillServe.$error">
          <small
            v-if="!$v.form.categoryOfConsumersPowerWillServe.required"
            class="text-danger"
            >This field is required</small
          >
        </p>
      </div>
      <div class="form-group">
        <label for="industrial/economic"
          >Which industrial/economic zones does your project connect power
          to?</label
        >
        <input
          type="text"
          class="form-control"
          @blur="$v.form.industrialZoneConnectPowerTo.$touch()"
          v-model="form.industrialZoneConnectPowerTo"
          placeholder="Which industrial/economic zones does your project connect power to?"
        />
        <p v-if="$v.form.industrialZoneConnectPowerTo.$error">
          <small
            v-if="!$v.form.industrialZoneConnectPowerTo.required"
            class="text-danger"
            >This field is required</small
          >
        </p>
      </div>
      <div class="form-group">
        <label for="industrial/economic"
          >Provide an indicative tariff range (1– 5cent kw/hr, 6-10,
          11-15,16-20,21-25,26-30,31-35, more than 35)</label
        >
        <input
          v-model="form.tarrifRange"
          @blur="$v.form.tarrifRange.$touch()"
          type="number"
          class="form-control"
        />
        <p v-if="$v.form.tarrifRange.$error">
          <small v-if="!$v.form.tarrifRange.required" class="text-danger"
            >This field is required</small
          >
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      form: {
        subsectorOfPower: "",
        sourceOfPowerGeneration: "",
        sourceOfEnergy: "",
        categoryOfConsumersPowerWillServe: "",
        industrialZoneConnectPowerTo: "",
        tarrifRange: "",
      },
    };
  },
  validations: {
    form: {
      subsectorOfPower: { required },
      sourceOfPowerGeneration: { required },
      sourceOfEnergy: { required },
      categoryOfConsumersPowerWillServe: { required },
      industrialZoneConnectPowerTo: { required },
      tarrifRange: { required },
    },
  },
  methods: {
    emitFormData() {
      this.$emit("stepUpdated", {
        data: this.form,
        valid: !this.$v.$invalid,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>