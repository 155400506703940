<template>
  <div class="col-md-10">
      
    <h2 class="pb-2">Basic Details</h2>
    <hr />

    <el-form    
        style="width:100%"
        :model="form"
        :rules="rules"
        ref="form"
        label-position="left"
        status-icon>
        <div class="form-row">

            <div class="col-md-12">
                <el-form-item label="Indicate the project category (Infrastructure, Service, Product,Commodities, etc.)" 
                prop="category">
                    <el-input type="text" v-model="form.category" placeholder="Enter project category"></el-input>
                </el-form-item>
            </div>

            <div class="col-md-12">
                <el-form-item label="Please indicate project commencement date" prop="commencementDate">
                    <el-input type="date" v-model="form.commencementDate" placeholder="Pick commencement date"></el-input>
                </el-form-item>
            </div>

            <div class="col-md-12">
                <el-form-item label="What is the current or proposed project location?" prop="location">
                    <el-input type="text" v-model="form.location" placeholder="Enter project location"></el-input>
                </el-form-item>
            </div>

            <div class="col-md-12">
                <el-form-item label="What is the size of your project in terms of funding requirement,budget or turnover? (provide range)" prop="project_funding">
                    <el-input type="text" v-model="form.size" placeholder="Enter range (GH₵: 20,0000 - GH₵: 30,000)"></el-input>
                </el-form-item>
            </div>

            <div class="col-md-12">
                <el-form-item prop="sectorRelationToAfcfta" 
                label="Please indicate which of the following sectors best describes your project in relation to the AFCTA?">
                    <el-select style="width:100%" v-model="form.sectorRelationToAfcfta" placeholder="Select project sector">
                        <el-option label="Agribusiness" value="Agribusiness" >Agribusiness</el-option>
                        <el-option value="Transport Corridors" label="Transport Corridors">Transport Corridors</el-option>
                        <el-option value="Agroprocessing" label="Agroprocessing">Agroprocessing</el-option>
                        <el-option value="Professional Services" label="Professional Services">Professional Services</el-option>
                        <el-option value="Health Systems Integration " label="Health Systems Integration ">
                            Health Systems Integration
                        </el-option>
                        <el-option value="General Manufacturing" label="General Manufacturing">General Manufacturing</el-option>
                        <el-option value="Trade & Logistics" label="Trade & Logistics">Trade & Logistics</el-option>
                        <el-option value="Hospitality & Tourism" label="Hospitality & Tourism">Hospitality & Tourism</el-option>
                        <el-option value="Education & Skills" label="Education & Skills">Education & Skills</el-option>
                        <el-option value="Road & Rail Network Infrastructure" label="Road & Rail Network Infrastructure">
                            Road & Rail Network Infrastructure
                        </el-option>
                        <el-option value="Energy Grids" label="Energy Grids">Energy Grids</el-option>
                        <el-option value="Water Management" label="Water Management">Water Management</el-option>
                        <el-option value="Clean Energy" label="Clean Energy">Clean Energy</el-option>
                        <el-option value="Ports & Terminals" label="Ports & Terminals">Ports & Terminals</el-option>
                        <el-option value="E-commerce" label="E-commerce">E-commerce</el-option>
                        <el-option value="E-government" label="E-government">E-government</el-option>
                        <el-option value="Fashion Value Chains" label="Fashion Value Chains">Fashion Value Chains</el-option>
                        <el-option value="Creative Arts" label="Creative Arts">Creative Arts</el-option>
                        <el-option value="Entertainment Production Hubs" label="Entertainment Production Hubs">
                            Entertainment Production Hubs
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>

            <div class="col-md-12">
                  <el-form-item prop="carriedOutInPhases" label="Will your project be carried out in phases">
                    <el-radio-group v-model="form.carriedOutInPhases">
                    <el-radio label="Yes"></el-radio>
                    <el-radio label="No"></el-radio>
                    </el-radio-group>
                </el-form-item>
            </div>
            <!-- phases will be here -->
            <div class="row" v-if="form.carriedOutInPhases === 'Yes'">
                <div class="col-md-12">
                    <p>what is the completion timeline for each phase</p>
                </div>
                <div class="col-md-12">
                  <div class="row"  v-for="(domain, index) in form.completionTimeForEachPhase" :key="domain.phase">
                      <div class="col-md-10 pb-0 mb-0">
                          <el-form-item
                            :label="`Phase ${index + 1}`"
                            :prop="'completionTimeForEachPhase.' + index + '.timeline'"
                            :rules="{
                                required: true, message: 'This field cannot be empty', trigger: 'blur'
                            }"
                            >
                            <el-input placeholder="Enter timeline" v-model="domain.timeline"></el-input>
                        </el-form-item> 
                      </div>
                      <div class="col-md-2 pt-md-5 my-0" v-if="form.completionTimeForEachPhase.length !== 1">
                          <el-button type="danger" @click.prevent="removeDomain(domain)">Delete</el-button>
                      </div>
                      <div class="pl-3 pt-0" v-if="index === form.completionTimeForEachPhase.length - 1">
                           <el-button type="primary" @click="addDomain(index)">New Phase</el-button>
                      </div>
                  </div>
                </div>
            </div>
            
           <div class="col-md-12">
                <el-form-item prop="overallCompletionDate" 
                    label="What is the overall expected completion date">
                    <el-select style="width:100%" v-model="form.overallCompletionDate" placeholder="Select completion date">
                        <el-option label="2020 - 2025" value="2020 - 2025" >2020 - 2025</el-option>
                        <el-option value="2025 - 2030" label="">2025 - 2030</el-option>
                        <el-option value="beyond 2030" label="beyond 2030">Beyond 2030</el-option>
                    </el-select>
                </el-form-item>
            </div>
            
            <div class="col-md-12">
                  <el-form-item prop="hasLongTermBuyer" 
                  label="Is there an off taker/long term identified buyer/ purchase contract for your project?">
                    <el-radio-group v-model="form.hasLongTermBuyer">
                    <el-radio  label="Yes"></el-radio>
                    <el-radio label="No"></el-radio>
                    </el-radio-group>
                </el-form-item>
            </div>

            <div class="col-md-12" v-if="form.hasLongTermBuyer === 'Yes'">
                <el-form-item prop="longTermBuyer" 
                    label="Select buyer / purchase contractor">
                    <el-select style="width:100%" v-model="form.longTermBuyer" 
                          placeholder="Select buyer / purchase contractor">
                        <el-option label="Government" value="Government" >Government</el-option>
                        <el-option value="Manufactures" label="Manufactures">Manufactures</el-option>
                        <el-option value="Households" label="Households">Households</el-option>
                        <el-option value="Other Industry" label="Other Industry">Other Industry</el-option>
                    </el-select>
                </el-form-item>
            </div>

        </div>

        <el-button @click="previous" type="primary" style="float:left">
            previous
        </el-button>
        <el-button
        type="primary"
        @click="next('form')"
        >Next</el-button>
    </el-form>
    
  </div>
</template>

<script>
export default {
    data() {
        return {
        form: {
                category: "",
                commencementDate: "",
                location: "",
                size: "",
                sectorRelationToAfcfta: "",
                overallCompletionDate: "",
                hasLongTermBuyer: "", //
                longTermBuyer: "",
                carriedOutInPhases: "", //,
                completionTimeForEachPhase: [
                    {
                        phase: 1,
                        timeline: ""
                    },
                ],
            },
        rules: {
            category: [
                {required: true, message:'Please this field is required', trigger:'blur'}
            ],
            commencementDate: [
                {required: true, message:'Please this field is required', trigger:'blur'}
            ],
            location: [
                {required: true, message:'Please this field is required', trigger:'blur'}
            ],
            size: [
                {required: true, message:'Please this field is required', trigger:'blur'}
            ],
            sectorRelationToAfcfta: [
                {required: true, message:'Please this field is required', trigger:'blur'}
            ],
            overallCompletionDate: [
                {required: true, message:'Please this field is required', trigger:'blur'}
            ],
            hasLongTermBuyer: [
                {required: true, message:'Please this field is required', trigger:'blur'}
            ],
            longTermBuyer: [
                {required: true, message:'Please this field is required', trigger:'blur'}
            ],
            carriedOutInPhases: [
                {required: true, message:'Please this field is required', trigger:'blur'}
            ]
        }
        }
    },

    methods: {
        removeDomain(item) {
        var index = this.form.completionTimeForEachPhase.indexOf(item);
        if (index !== -1) {
          this.form.completionTimeForEachPhase.splice(index, 1);
        }
      },
      addDomain(index) {
        this.form.completionTimeForEachPhase.push({
          phase: index + 2,
          timeline: ''
        });
      },
        previous(){
             this.$emit('previousComponent', {
                 name:'BasicInfo'
             })
        },
        next(form){
            this.$refs[form].validate( valid => {
                if( valid ) {
                    if(this.form.carriedOutInPhases === 'No') {
                        this.form.completionTimeForEachPhase = {

                        }
                    }
                    // this.form.is_project_contractor === "Yes" ? this.form.is_project_contractor = 'true' : this.form.is_project_contractor = 'false'
                    // this.form.carriedOutInPhases === 'Yes' ? this.form.carriedOutInPhases = 'true' : this.form.carriedOutInPhases = 'false'
         // if validation successfully pass data to parent component and move to the next component
                    this.$emit('stepUpdate', {
                       next:'TransportProject',
                       form: this.form
                    }) 
                  
                } else {
                    
                }
            })
        }
    }
}
</script>

<style scoped>
button{
    float:right;
}
</style>