<template>
  <div class="col-md-10">
      
    <h2 class="pb-2">Basic Profile</h2>
    <hr />
    <el-form    
        style="width:100%"
        :model="form"
        :rules="rules"
        ref="form"
        label-position="left"
        status-icon>

        <div class="form-row">
            <div class="col-md-12">
                <h5>Project Organization/Company</h5>
            </div>
            <div class="form-group col-md-6">
                <el-form-item label="Name" prop="organizationName">
                    <el-input v-model="form.organizationName" placeholder="Enter your organization name"></el-input>
                </el-form-item>
            </div>
            <div class="form-group col-md-6">
                <el-form-item label="Email" prop="emailAddress">
                    <el-input type="email" v-model="form.emailAddress" placeholder="Enter your organization email address"></el-input>
                </el-form-item>
            </div>
            <div class="form-group col-md-6">
                <el-form-item  prop="telephoneNumber" label="Enter your organization phone number">
                    <vue-tel-input class="form-control"
                        v-model="form.telephoneNumber"
                    ></vue-tel-input>
                </el-form-item>
            </div>
        </div>

        <div class="form-row">
            <div class="col-md-12">
               <h5>Contact Person</h5>
            </div>
            <div class="form-group col-md-6">
                <el-form-item label="Name" prop="nameOfContactPerson">
                    <el-input v-model="form.nameOfContactPerson" placeholder="Enter contact person name"></el-input>
                </el-form-item>
            </div>
            <div class="form-group col-md-6">
                <el-form-item label="Email" prop="emailAddressOfContactPerson">
                    <el-input type="email" v-model="form.emailAddressOfContactPerson" placeholder="Enter contact person email address"></el-input>
                </el-form-item>
            </div>
            <div class="form-group col-md-6">
                <el-form-item  prop="telephoneNumberOfContactPerson" label="Enter contact person phone number">
                    <vue-tel-input class="form-control"
                        v-model="form.telephoneNumberOfContactPerson"
                    ></vue-tel-input>
                </el-form-item>
            </div>
        </div>
        <div class="form-row">
            <div class="col-md-12">
                <el-form-item prop="sector" label="In what sector is your project/proposed project (Primary, secondary, tertiary)">
                    <el-select style="width:100%" v-model="form.sector" placeholder="Select project sector">
                    <el-option label="Primary" value="Primary"></el-option>
                    <el-option label="Secondary" value="Secondary"></el-option>
                    <el-option label="Tertiary" value="Tertiary"></el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div class="col-md-12">
                <el-form-item  :filterable="true" prop="industry" label="Which of the following industry(ies) will your project be classified under?">
                    <el-select style="width:100%" v-model="form.industry" placeholder="Select industry">
                        <el-option value="Construction" label="Construction">Construction</el-option>
                        <el-option value="Oil & Gas" label="Oil & Gas">Oil & Gas</el-option>
                        <el-option value="Agriculture, Forestry & Fishing" label="Agriculture, Forestry & Fishing">
                        Agriculture, Forestry & Fishing
                        </el-option>
                        <el-option value="Agribusiness" label="Agribusiness">Agribusiness</el-option>
                        <el-option value="Automotive" label="Automotive">Automotive</el-option>
                        <el-option value="Manufacturing" label="Manufacturing">Manufacturing</el-option>
                        <el-option value="Hospitality" label="Hospitality">Hospitality</el-option>
                        <el-option value="Media & Entertainment" label="Media & Entertainment">Media & Entertainment</el-option>
                        <el-option value="Tourism & Recreation" label="Tourism & Recreation">Tourism & Recreation</el-option>
                        <el-option value="Arts & Culture" label="Arts & Culture">Arts & Culture</el-option>
                        <el-option value="Minning & Quarrying" label="Minning & Quarrying">Minning & Quarrying</el-option>
                        <el-option value="Transportation & Warehousing" label="Transportation & Warehousing">
                        Transportation & Warehousing
                        </el-option>
                        <el-option value="Financial & Insurance Services" label="Financial & Insurance Services">
                        Financial & Insurance Services
                        </el-option>
                        <el-option value="Financial Technology (Fintech)" label="Financial Technology (Fintech)">
                        Financial Technology (Fintech)
                        </el-option>
                        <el-option value="Information & Communication Technology" label="Information & Communication Technology">
                        Information & Communication Technology
                        </el-option>
                        <el-option value="Real Estate" label="Real Estate">Real Estate</el-option>
                        <el-option value="Electricity & Power Generation" label="Electricity & Power Generation">
                        Electricity & Power Generation
                        </el-option>
                        <el-option value="Renewable Energy" label="Renewable Energy">Renewable Energy</el-option>
                        <el-option value="Consumer Durables & Apparels" label="Consumer Durables & Apparels">
                        Consumer Durables & Apparels
                        </el-option>
                        <el-option value="Wholesale & Retail Trade" label="Wholesale & Retail Trade">
                        Wholesale & Retail Trade
                        </el-option>
                        <el-option value="Healthcare Equipment & Services" label="Healthcare Equipment & Services">
                        Healthcare Equipment & Services
                        </el-option>
                        <el-option value="Pharmaceutical" label="Pharmaceutical">Pharmaceutical</el-option>
                        <el-option value="Education" label="Education">Education</el-option>
                        <el-option value="Utilities" label="Utilities">Utilities</el-option>
                        <el-option value="Food & Beverage" label="Food & Beverage">Food & Beverage</el-option>
                        <el-option label="other" value="other">other</el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div class="col-md-12">
                <el-form-item label="What is your country of incorporation?" prop="country">
                    <el-select :filterable="true" style="width:100%" v-model="form.country" prop="country" placeholder="Select country">
                     <el-option v-for="(country, key) in countries" :key="key" :label="country.name" 
                     :value="country.name"></el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div class="col-md-12">
                  <el-form-item prop="companyAffiliatedWithBusinessAssociation" label="Is your company affiliated with any business association?">
                    <el-radio-group v-model="form.companyAffiliatedWithBusinessAssociation">
                    <el-radio value='true' label="Yes"></el-radio>
                    <el-radio value="false" label="No"></el-radio>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="col-md-12" v-if="form.companyAffiliatedWithBusinessAssociation === 'Yes'">
                <el-form-item label="If yes please provide details" prop="companyAffiliatedWithBusinessAssociationDetails">
                    <el-input type="textarea" col="4" v-model="form.companyAffiliatedWithBusinessAssociationDetails"></el-input>
                </el-form-item>
            </div>
            <div class="col-md-6">
                <el-form-item label="Indicate the type of Company" prop="companyType">
                    <el-select style="width:100%"  v-model="form.companyType" placeholder="Select company type">
                    <el-option label="SME" value="SME"></el-option>
                    <el-option label="Multi-national" value="Multi-national"></el-option>
                    <el-option label="Large Company" value="Large Company"></el-option>
                    <el-option value="Other" label="Other"></el-option>
                    </el-select>
                </el-form-item>
            </div>
            
            
                 
            <div class="col-md-6">
                
                <el-form-item label="Kindly enter your AfCFTA number" prop="afcftaNumber">
                    <el-input type="text" v-model="form.afcftaNumber" ></el-input>
                </el-form-item>
            </div>
            
        </div>
            <el-button type="primary" @click="next('form')">
                Next
            </el-button>
       
    </el-form> 
  </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import VueCountrySelect from "vue-country-select";
import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
    name:'basic_info', 
    components: {
        VueTelInput,
        VueCountrySelect,
    },
    data( ) {
        const validatecompanyAffiliatedWithBusinessAssociationDetails = (rule, value, callback) => {
            if(this.form.companyAffiliatedWithBusinessAssociation === 'Yes') {
                if(value === '') {
                    callback(new Error('Please this field is required'))
                } else {
                    callback()
                } 
            }
        }
        
        return {
            isValid: false,
            form: {
                organizationName: "",
                emailAddress: "",
                telephoneNumber: "",
                nameOfContactPerson: "",
                emailAddressOfContactPerson: "",
                telephoneNumberOfContactPerson: "",
                sector: "",
                industry: "",
                country: "",
                companyAffiliatedWithBusinessAssociation: '',
                companyAffiliatedWithBusinessAssociationDetails: "",
                companyType: "",
                afcftaNumber: "",
            },
            rules: {
                organizationName: [
                   { required: true, message: 'Please this field is required', trigger: 'change' }
                ],
                emailAddress: [
                    {required: true, message: 'Please this field is required', trigger: 'change' },
                    {type:'email', message: 'Enter a valid email', trigger: 'change'}
                ],
                telephoneNumber: [
                    { required: true, message: 'Please this field is required', trigger: 'change' }
                ],
                nameOfContactPerson: [
                    { required: true, message: 'Please this field is required', trigger: 'change' }
                ],
                emailAddressOfContactPerson: [
                    {required: true, message: 'Please this field is required', trigger: 'change' },
                    {type:'email', message: 'Enter a valid email', trigger: 'change'}
                 ],
                telephoneNumberOfContactPerson: [
                    { required: true, message: 'Please this field is required', trigger: 'change' }
                ],
                industry: [
                    { required: true, message: 'Please this field is required', trigger: 'change' }
                ],
                sector: [
                    { required: true, message: 'Please this field is required', trigger: 'change' }
                ],
                country: [
                    { required: true, message: 'Please this field is required', trigger: 'change' }
                ],
                companyAffiliatedWithBusinessAssociation: [
                     { required: true, message: 'Please this field is required', trigger: 'change' }
                ],
                companyAffiliatedWithBusinessAssociationDetails: [
                    {
                        validator: validatecompanyAffiliatedWithBusinessAssociationDetails, trigger:['change', 'blur']
                    }
                ],
                companyType: [
                   { required: true, message: 'Please this field is required', trigger: 'change' }
                ],
                afcftaNumber: [
                    { required: true, message: 'Please this field is required', trigger: 'change' }
                ]
            },
        }
    },
    computed: {
        ...mapGetters(['countries'])
    },
    methods: {
        next(form){
            this.$refs[form].validate( valid => {
                if( valid ) {
                    // this.form.companyAffiliatedWithBusinessAssociation === 'Yes' ? this.form.companyAffiliatedWithBusinessAssociation = 'true' : this.form.companyAffiliatedWithBusinessAssociation = 'false'
                    // if validation successfully pass data to parent component and move to the next component
                    this.$emit('stepUpdate', {
                       next:'BasicDetails',
                       form: this.form
                    }) 
                } else {
                   
                }
             })
        }
    },
    created() {
        this.$store.dispatch('fetchCountries')
    }
}
</script>

<style scoped>
button{
    float: right;
}
</style>