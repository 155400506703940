<template>
  <form @input="emitFormData" class="col-md-10">
    <h2 class="pb-2">Afican Contents</h2>
    <hr />
    <label for=""
      >Kindly indicate the number of people on your board and their
      nationalities</label
    >
    <div class="form-row">
      <div class="form-group col-md-6">
        <input
          type="text"
          class="form-control"
          placeholder="Enter Board Member"
          v-model="form.numberOfPeopleOnBoardAndNationalities"
        />
      </div>
      <div class="form-group col-md-6">
        <select
          id="inputState"
          class="form-control"
        >
          <option selected>Choose...</option>
          <option>Ghanaian</option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <input
          type="text"
          class="form-control"
          placeholder="Enter Board Member"
          v-model="form.numberOfPeopleOnBoardAndNationalities"
        />
      </div>
      <div class="form-group col-md-6">
        <select
          id="inputState"
          class="form-control"
        >
          <option selected>Choose...</option>
          <option>Ghanaian</option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <input
          type="text"
          class="form-control"
          placeholder="Enter Board Member"
          v-model="form.numberOfPeopleOnBoardAndNationalities"
        />
      </div>
      <div class="form-group col-md-6">
        <select
          id="inputState"
          class="form-control"
        >
          <option selected>Choose...</option>
          <option>Ghanaian</option>
        </select>
      </div>
    </div>
    <label for=""
      >What is the percentage gender composition on your board?</label
    >
    <div class="form-row">
      <div class="form-group col-md-6">
        <div class="form-group">
          <label class="pl-4 pt-2" for="male">Male</label>
          <br /><br />
          <br />
          <div>
            <label class="pl-4" for="female">Female</label>
          </div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <input
          type="text"
          placeholder="percentage gender composition"
          class="form-control"
          v-model="form.percentageGenderComposition"
        />
        <br />
        <input
          type="text"
          placeholder="percentage gender composition"
          class="form-control"
          v-model="form.percentageGenderComposition"
        />
      </div>
      <div class="form-group">
        <label for="project directly">Is the Project Company Listed ? </label>
        &nbsp; &nbsp;
        <div class="custom-control custom-control-inline">
          <div class="radio">
            <label><input v-model="form.companyListed" type="radio" name="optradio" /> Yes</label>
          </div>
        </div>
        <div class="custom-control custom-control-inline">
          <div class="radio">
            <label><input v-model="form.companyListed" type="radio" name="optradio" /> No</label>
          </div>
        </div>
      </div>
    </div>
    <label for=""
      >Indicate the number of senior management members and their
      nationalities</label
    >
    <div class="form-row">
      <div class="form-group col-md-6">
        <input
          type="text"
          class="form-control"
          placeholder="Enter senior management member"
          v-model="form.numberOfSeniorMembersAndNationalities"
        />
      </div>
      <div class="form-group col-md-6">
        <select  id="inputState" class="form-control">
          <option selected>Choose...</option>
          <option>Ghanaian</option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <input
          type="text"
          class="form-control"
          placeholder="Enter senior management member"
          v-model="form.numberOfSeniorMembersAndNationalities"
        />
      </div>
      <div class="form-group col-md-6">
        <select id="inputState" class="form-control">
          <option selected>Choose...</option>
          <option>Ghanaian</option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <input
          type="text"
          class="form-control"
          placeholder="Enter senior management member"
           v-model="form.numberOfSeniorMembersAndNationalities"
        />
      </div>
      <div class="form-group col-md-6">
        <select id="inputState" class="form-control">
          <option selected>Choose...</option>
          <option>Ghanaian</option>
        </select>
      </div>
    </div>
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      form: {
        numberOfPeopleOnBoardAndNationalities:{},
        percentageGenderComposition: {},
        companyListed:true,
        numberOfSeniorMembersAndNationalities:{},
      },
    };
  },
  form:{
     numberOfPeopleOnBoardAndNationalities:{required},
        percentageGenderComposition: {required},
        companyListed:{required},
        numberOfSeniorMembersAndNationalities:{required},
  }
};
</script>

<style lang="scss" scoped>
</style>