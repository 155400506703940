<template>
  <div class="col-10">
    <h2 class="pb-2">Transportation Projects</h2>
    <hr />
    <div class="form-group">
      <label for="transportation"
        >Indicate the mode of transportation that the project can be categorized
        under
        <span class="text-info"
          >(rail, road, air, pipeline, maritime, or intermodal)</span
        ></label
      >
      <select
        v-model="formData.modeOfTransportation"
        id="inputState"
        class="form-control"
      >
        <option selected>Choose...</option>
        <option>Rail</option>
        <option>Road</option>
        <option>Air</option>
        <option>Pipeline</option>
        <option>Maritime</option>
      </select>
    </div>
    <div class="form-group">
      <label for="transportation"
        >what is the expected carriage?
        <span class="text-info">(cargo or passenger dropdown)</span>.</label
      >
      <select v-model="formData.expectedCarriage" class="form-control">
        <option selected>Choose...</option>
        <option>Cargo</option>
        <option>Passenger dropdown</option>
      </select>
    </div>
    <div class="form-group">
      <label for="company affiliated"
        >Indicate whether the service is domestic or international;
      </label>
      &nbsp;
      <span
        ><input
          v-model="formData.serviceType"
          type="radio"
          name="service"
          id=""
        />
        domestic
        <input
          v-model="formData.serviceType"
          type="radio"
          name="service"
          id=""
        />
        international</span
      >
      <div class="form-group">
        <label for="exampleFormControlTextarea1"
          >If International, what countries are being connected.</label
        >
        <input
          type="text"
          class="form-control"
          v-model="formData.countriesConnected"
          placeholder="what countries are being connected"
        />
      </div>
    </div>
    <div class="form-group">
      <label for="transportation"
        >Which of these cities/countries does your project connects to?
      </label>
      <select
        v-model="formData.citiesOrCountriesConnected"
        class="form-control"
      >
        <option selected>Choose...</option>
      </select>
    </div>
    <div class="form-group">
      <label for="digital"
        >Please Indicate in what digital activity your project is (mobile,
        satellite, fiber, financial, others)</label
      >
      <input
        type="text"
        v-model="formData.digitalActivity"
        class="form-control"
        id="inputEmail4"
        placeholder="Indicate in what digital activity your project is"
      />
    </div>
    <div class="form-group">
      <label for="inputAddress"
        >What percentage of the digital connectivity gap will the project
        fill?</label
      >
      <input
        type="number"
        v-model="formData.percentageOfDigitalCurrencyGap"
        class="form-control"
        placeholder="the digital connectivity"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        modeOfTransportation: "",
        expectedCarriage: "",
        serviceType: "",
        countriesConnected: [],
        citiesOrCountriesConnected: [],
        digitalActivity: "",
        percentageOfDigitalCurrencyGap: "",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>