import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    countries:[]
  },
  mutations: {
  ADD_COUNTRIES(state, data){
    state.countries = data
  }
  },
  actions: {
    fetchCountries({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`https://api.trilliondollarafrica.org/countries`)
        .then(response => {
          commit('ADD_COUNTRIES', response.data)
          resolve(response)
        }).catch( err => reject(err))
      })

    }
  },
  getters: {
    countries: state => state.countries
  },
  modules: {
  }
})
