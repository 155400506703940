<template>
  <div class="col-md-10">
    <h2 class="pb-2">African Value Chain</h2>
    <hr />
    <el-form
      style="width: 100%"
      :model="form"
      :rules="rules"
      ref="form"
      status-icon
      label-position="left"
    >
      <div class="col-md-12">
        
      </div>
      <!-- INDICATE PERCENTAGE OF INPUTS PER COUNTRY -->
      <div class="row">
            <div class="col-md-12">
                <p>Select the countries you source input from and indicate the percentage of inputs that will be sourced from each country</p>
            </div>
        </div>
        <div class="row" v-for="(data, key) in form.countriesSourceInputsFromAndPercentage" :key="`some${key}`">
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-6 col-lg-6">
                        <el-form-item 
                            :rules="{required: true, message:'Please this field is required', trigger:'change'}"
                            :prop="'countriesSourceInputsFromAndPercentage.' + key + '.country'"
                            >
                            <el-select placeholder="Select Nationality" v-model="data.country">
                                <el-option v-for="(country, key) in countries" :key="key" :label="country.name" :value="country.name">
                                    {{ country.name }} 
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
              
                <div class="col-md-6 col-sm-6 col-6 col-lg-6">
                    <el-form-item  
                    :rules="{required: true, message:'Please this field is required', trigger:'change'}"
                    :prop="'countriesSourceInputsFromAndPercentage.' + key + '.percentage'"
                    >
                        <el-input type="text" v-model="data.percentage" placeholder ="Enter number of people"></el-input>
                    </el-form-item>
                </div>
                    
                </div>
                </div>
                <div class="col-md-2 mb-1" v-if="form.countriesSourceInputsFromAndPercentage.length !== 1">
                    <el-button style="float:right" type="danger" @click="deleteInput" >Delete</el-button>
                </div>
                <div class="col-md-12" v-if="key === form.countriesSourceInputsFromAndPercentage.length -1">
                        <el-button style="float:left" @click="addInput(key)" type="primary"  >Add</el-button>
                </div>
        </div>
      <!-- END OF INDICATE PERCENTAGE OF INPUTS PER COUNTRY -->
      <div class="col-md-12">
        <el-form-item
          :filterable="true"
          prop="inputsSourced"
          label="What inputs do you source out of Africa "
        >
          <el-select
            style="width: 100%"
            v-model="form.inputsSourced"
            placeholder="select inputs"
            :multiple="true"
          >
            <el-option value="materials" label="Materials">Materials</el-option>
            <el-option value="technology" label="technology"
              >Technology</el-option
            >
            <el-option value="Management" label="Management"
              >Management</el-option
            >
          </el-select>
        </el-form-item>
      </div>
      <div class="col-md-12">
        <el-form-item
          label="Which of these countries are the primary consumers of your products/services?"
          prop="primaryConsumerCountries"
        >
          <el-select
            :filterable="true"
            style="width: 100%"
            :multiple="true"
            v-model="form.primaryConsumerCountries"
            prop="country"
            placeholder="Select country"
          >
            <el-option
              v-for="(country, key) in countries"
              :key="key"
              :label="country.name"
              :value="country.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="col-md-12">
        <el-form-item
          prop="productExported"
          label="Will your products/services be exported?"
        >
          <el-radio-group v-model="form.productExported">
            <el-radio  label="Yes"></el-radio>
            <el-radio  label="No"></el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="col-md-12" v-if="form.productExported === 'Yes' ">
        <el-form-item
          label="Which of these countries do you consider the primary destinations for
        export?"
          prop="primaryExportDestinationCountries"
        >
          <el-select
            :filterable="true"
            style="width: 100%"
            v-model="form.primaryExportDestinationCountries"
            prop="country"
            :multiple="true"
            placeholder="Select country"
          >
            <el-option
              v-for="(country, key) in countries"
              :key="key"
              :label="country.name"
              :value="country.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="col-md-12" v-if="form.productExported === 'Yes' ">
        <el-form-item
          label="What is the route that your input goes through before arriving at your
        location?"
          prop="routeInputGoesThrough"
        >
          <el-input
            type="textarea"
            :rows="2"
            placeholder="Please input"
            v-model="form.routeInputGoesThrough"
          >
          </el-input>
        </el-form-item>
      </div>

      <el-button @click="previous" type="primary" style="float:left">
          previous
      </el-button>
      <el-button
        type="primary"
        @click="next('form')">
            Next
      </el-button>

    </el-form>
  </div>
</template>

<script>
import VueCountrySelect from "vue-country-select";
import { mapGetters } from "vuex";
export default {
  components: {
    VueCountrySelect,
  },
  data() {
    return {
        isValid: false,
        inputCountries:[],
      form: {
        countriesSourceInputsFromAndPercentage: [
          {
            country:'',
            percentage:'',
          }
        ],
        inputsSourced: [],
        primaryConsumerCountries: [],
        productExported: '',
        primaryExportDestinationCountries: [],
        routeInputGoesThrough: "",
      },
      rules: {
        inputCountries:[
          { required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        primaryConsumerCountries:[
          { required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        primaryExportDestinationCountries:[
          { required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        productExported:[
          { required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        country: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
        inputsSourced: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
        routeInputGoesThrough: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["countries"]),
  },
  created() {
    this.$store.dispatch("fetchCountries");
  },
  methods: {
    deleteInput(key) {
      this.form.countriesSourceInputsFromAndPercentage.splice(key, 1)
    },
    addInput() {
      this.form.countriesSourceInputsFromAndPercentage.push({
        country: '',
        percentage: '',
      })
    },
    previous(){
             this.$emit('previousComponent', {
                 name:'ownershipStructure'
             })
        },
        next(form){
            this.$refs[form].validate( valid => {
                if( valid ) {
                  // if validation successfully pass data to parent component and move to the next component
                    this.$emit('stepUpdate', {
                       next:'AfricanContent',
                       form: this.form
                    }) 

                } else {
                    
                }
            })
        }
  }
};
</script>

<style lang="scss" scoped>
</style>