<template>
  <div class="col-md-10">
    <h2>Others</h2>
    <hr />
    <el-form
      style="width: 100%"
      :rules="rules"
      :model="form"
      ref="form"
      label-position="left"
      status-icon>
      <div class="col-md-12">
        <el-form-item
          prop="requireComplementaryInfrasturacture"
          label="Will the implementation of your project require complementary
          infrastructure?"
        >
          <el-radio-group
            v-model="form.requireComplementaryInfrasturacture"
          >
            <el-radio value="true" label="Yes"></el-radio>
            <el-radio value="false" label="No"></el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
       <div class="col-md-12">
        <el-form-item
          prop="fundedPlanForDevelopingComplementaryInfrasturacture"
          label="If yes, do you have a funded plan for developing complementary
          infrastructure?"
        >
          <el-radio-group
            v-model="form.fundedPlanForDevelopingComplementaryInfrasturacture"
          >
            <el-radio value="true" label="Yes"></el-radio>
            <el-radio value="false" label="No"></el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
       <div class="col-md-12">
        <el-form-item
          prop="createdStemBasedTalent"
          label="Will the project create STEM-based talent pipeline to manage
          African's industrialization and technological competitiveness? –
          Value Addition"
        >
          <el-radio-group
            v-model="form.createdStemBasedTalent"
          >
            <el-radio value="true" label="Yes"></el-radio>
            <el-radio value="false" label="No"></el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
        <div class="col-md-12">
        <el-form-item
          prop="promoteInnovation"
          label="Will the project promote the use of R&D and Innovation, mass registration of patents, and
          commercialization of R&D for value creation and industrialization?"
        >
        <div class="row" v-if="error">
          <div class="col-md-12">
            <div class="alert alert-danger">
                {{ error }}
            </div>
          </div>
        </div>

         <div class="row" v-if="success">
          <div class="col-md-12">
            <div class="alert alert-success">
                {{ success }}
            </div>
          </div>
        </div>
          <el-radio-group
            v-model="form.promoteInnovation"
          >
            <el-radio value="true" label="Yes"></el-radio>
            <el-radio value="false" label="No"></el-radio>
          </el-radio-group>
        </el-form-item>
        
         <el-button @click="previous" type="primary" style="float:left">
            previous
        </el-button>
        <el-button
        type="primary"
        @click="submit('form')"
        :loading="isLoading"
        >
          Submit
        </el-button>

      </div>
    </el-form>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      allFormData: {},
      isLoading: false,
      success: null,
      error:null,
      form: {
        requireComplementaryInfrasturacture: '',
        fundedPlanForDevelopingComplementaryInfrasturacture: '',
        createdStemBasedTalent: '',
        promoteInnovation: '',
      },
      rules:{
        requireComplementaryInfrasturacture:[
          {required:true, message:'Please this field is required', trigger:'change'}
        ],
        fundedPlanForDevelopingComplementaryInfrasturacture:[
          {required:true, message:'Please this field is required', trigger:'change'}
        ],
        createdStemBasedTalent:[
          {required:true, message:'Please this field is required', trigger:'change'}
        ],
        promoteInnovation:[
          {required:true, message:'Please this field is required', trigger:'change'}
        ]
      }
    };
  },
  methods: {
        previous(){
             this.$emit('previousComponent', {
                 name:'sdgAndScale'
             })
        },
         submit(form) {
          this.$refs[form].validate( valid => {
                if( valid ) { 
                  this.isLoading = true
                  this.allFormData = {...this.allFormData,...this.form}
                   axios.post('https://api.trilliondollarafrica.org/project',
                  this.allFormData).then(response => {
                    this.isLoading = false
                    if(response) {
                    this.success = 'Data submission successfully'
                  }
                  }).catch(error => {
                     this.isLoading = false

                    if(error) {
                      this.error = "An unexpected error occurs try again"
                    }
                  })
                  
                  
                } else {
                  return 
                }
          }
          )
          
        }
        
  },  
  mounted() {
    this.allFormData = JSON.parse(localStorage.getItem('form'))
    console.log('Get all form data',this.allFormData)
  }
};
</script>

<style lang="scss" scoped>
</style>