import { render, staticRenderFns } from "./transportProject.vue?vue&type=template&id=a80ff414&scoped=true&"
import script from "./transportProject.vue?vue&type=script&lang=js&"
export * from "./transportProject.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a80ff414",
  null
  
)

export default component.exports