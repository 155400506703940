<template>
    <div class="col-md-10">
        <h2 class="pb-2">Ownership Structure</h2>
        <hr />
        <el-form
        style="width: 100%"
        :model="form"
        ref="form"
        status-icon
        label-position="left"
        >       
            <div class="row">
                <!-- <div class="col-md-12" v-for="(source, key) in form.sourceOfFundingAndPercentages" :key="key">

                </div> -->
                <!-- implement check source funding -->
               
                
                    <div class="col-md-12">
                        <p>Please indicate the category of shareholder and their percentage of ownership</p>
                        <el-form-item label="Select Category"
                            :rules="{required: true, message: 'This field cannot be empty', trigger: 'change'}"
                            prop="selectedShareHolders"
                            >
                            <el-checkbox-group 
                                v-model="form.selectedShareHolders">
                                <el-checkbox v-for="(city, key) in shareholders" :label="city" :key="city"  @change="getShareHolder(key, city)"  >{{city}}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </div>
                     <div class="col-md-12" v-if="form.selectedShareHolders.length > 0">
                         <p >Enter percentage of ownerships</p>
                     </div>
                    <div class="col-md-12" v-for="(activity, key) in form.selectedShareHolders" :key="activity">
                        <el-form-item 
                            :label="activity" 
                            :rules="{required: true, message: 'This field cannot be empty', trigger: 'blur'}"
                            :prop="'shareHolderCategoryAndPercentages.' + key + '.percentage'" >
                            <el-input type="number"  placeholder="Enter percentage gap" v-model="form.shareHolderCategoryAndPercentages[key].percentage"></el-input>
                        </el-form-item>
                    </div>
            
                <!-- source of funding section -->
                 
                    <div class="col-md-12">
                        <el-form-item label="Indicate your source(s) of funding "
                        :rules="{required: true, message: 'This field cannot be empty', trigger: 'blur'}"
                                prop="selectedFundingSources">
                            <el-checkbox-group 
                                v-model="form.selectedFundingSources"
                                >
                                <el-checkbox v-for="(city, key) in fundingSources" :label="city" :key="city"  @change="getFundingSource(key, city)"  >{{city}}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </div>
                     <div class="col-md-12" v-if="form.selectedFundingSources.length > 0">
                         <p>What is the percentage composition for each of the sources</p>
                     </div>
                    <div class="col-md-12" v-for="(activity, key) in form.selectedFundingSources" :key="activity">
                       
                        <el-form-item 
                            :label="activity"
                            :rules="{required: true, message: 'This field cannot be empty', trigger: 'blur'}"
                            :prop="'sourceOfFundingAndPercentages.' + key + '.percentage'" >
                            <el-input type="number"  placeholder="Enter percentage gap" v-model="form.sourceOfFundingAndPercentages[key].percentage"></el-input>
                        </el-form-item>
                    </div>
                

                <div class="col-md-12">
                    <el-form-item
                    :filterable="true"
                    label="Indicate the kind of government support anticipated/received"
                    prop="kindOfGOvernmentSupports"
                    :rules="{required: true, message: 'This field cannot be empty', trigger: 'blur'}"
                    >
                    <el-select
                        style="width: 100%"
                        v-model="form.kindOfGOvernmentSupports"
                        :multiple="true"
                        placeholder="select source of energy"
                    >
                        <el-option value="Grants" label="Grants"
                        >Grants</el-option
                        >
                        <el-option value="Loans" label="Loans"
                        >Loans</el-option
                        >
                        <el-option value="Guarantees" label="Guarantees"
                        >Guarantees</el-option
                        >
                        <el-option value="Put or Call Option" label="Put or Call Option"></el-option>
                        <el-option label="Reliefs" value="Reliefs"></el-option>
                        <el-option value="Government consent and agreement" label="Government consent and agreement"></el-option>
                    </el-select>
                    </el-form-item>
                </div>

                <div class="col-md-12">
                    <el-button @click="previous" type="primary" style="float:left">
                        previous
                    </el-button>
                    <el-button
                        type="primary"
                        @click="next('form')"
                        >
                        Next
                    </el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            shareholders : ['Individuals', 'Government', 'Private Equity', 'Development Finance Institutions (DFIs)', 'Other Institutional Shareholders'],
            selectedShareHolders:[],
            fundingSources:['Debt', 'Equity', 'IGF', 'Other'],
            selectedFundingSources:[],

            form: {
                selectedFundingSources:[],
                selectedShareHolders:[],
                kindOfGOvernmentSupports:[],
                sourceOfFundingAndPercentages:[
                    {
                        source:'',
                        percentage:''
                    },
                    {
                        source: '',
                        percentage: ''
                    },
                    {
                        source:'',
                        percentage: ''
                    },
                    {
                        source:'',
                        percentage: ''
                    }
                ],
                shareHolderCategoryAndPercentages:[
                    {
                        category:'',
                        percentage:''
                    },
                    {
                        category:'',
                        percentage:''
                    },
                    {
                        category:'',
                        percentage:''
                    },
                    {
                        category:'',
                        percentage:''
                    },
                    {
                        category:'',
                        percentage:''
                    }
                ]
            },
        
        }
    },
    methods: {
        getShareHolder(key, data) {
            this.form.shareHolderCategoryAndPercentages[key].category = data
        },
        getFundingSource(key, data) {
            this.form.sourceOfFundingAndPercentages[key].source = data
        },
        previous(){
             this.$emit('previousComponent', {
                 name:'NonTarifficBar'
             })
        },
        next(form){
            this.$refs[form].validate( valid => {
                if( valid ) {
                delete this.form.selectedShareHolders
                delete this.form.selectedFundingSources
         // if validation successfully pass data to parent component and move to the next component
                    this.$emit('stepUpdate', {
                       next:'AfricanValueChain',
                       form: this.form
                    }) 
                   
                   
                } else {
                    
                }
            })
        }
    }
}
</script>

<style>

</style>