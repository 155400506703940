<template>
  <div class="col-md-10">
    <h2 class="pb-2">Non-tariff Barriers</h2>
    <hr />
    <form @input="emitFormData">
      <div class="form-group">
        <label for="project directly"
          >Does the project incorporate digitization features/capabilities in
          customs processes?
        </label>
        <br />&nbsp; &nbsp;
        <div class="custom-control custom-control-inline">
          <div class="radio">
            <label
              ><input
                v-model="form.incorporateDigitizationInCustomProcesses"
                type="radio"
                name="optradio"
              />
              Yes</label
            >
          </div>
        </div>
        <div class="custom-control custom-control-inline">
          <div class="radio">
            <label
              ><input
                v-model="form.incorporateDigitizationInCustomProcesses"
                type="radio"
                name="optradio"
              />
              No</label
            >
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="project directly"
          >Does the project allow for tracking of goods sent across borders?
        </label>
        <br />
        <div class="custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            id="customRadioInline1"
            name="tracking"
            v-model="form.allowTrackingOfGoodsSendAcrossBorders"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadioInline1"
            >Yes</label
          >
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            id="customRadioInline2"
            name="tracking"
            class="custom-control-input"
            v-model="form.allowTrackingOfGoodsSendAcrossBorders"
          />
          <label class="custom-control-label" for="customRadioInline2"
            >No</label
          >
        </div>
      </div>

      <div class="form-group">
        <label for="project directly"
          >Will the project help extend border post open times and create one
          stop border posts?
        </label>
        <br />
        &nbsp; &nbsp;
        <div class="custom-control custom-radio custom-control-inline">
          <div class="radio">
            <label
              ><input
                v-model="form.helpExtendBorderPostOpenTimes"
                type="radio"
                name="project"
              />
              Yes</label
            >
          </div>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <div class="radio">
            <label
              ><input
                v-model="form.helpExtendBorderPostOpenTimes"
                type="radio"
                name="project"
              />
              No</label
            >
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="project directly"
          >Does the project allow for standards/norms in one African country to
          be recognized in another?
        </label>
        &nbsp; &nbsp;
        <div class="custom-control custom-radio custom-control-inline">
          <label
            ><input
              v-model="form.allowForStandardsToBeRecognized"
              type="radio"
              name="standard"
            />
            Yes</label
          >
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <label
            ><input
              v-model="form.allowForStandardsToBeRecognized"
              type="radio"
              name="standard"
            />
            No</label
          >
        </div>
      </div>

      <div class="form-group">
        <label for="raw material"
          >Which of these countries will have mutual recognition for the
          standards/norms created?
        </label>
        <select
          multiple="multiple"
          v-model="form.countriesMutualRecognitionBeCreated"
          @blur="$v.form.countriesMutualRecognitionBeCreated.$touch()"
          class="form-control"
        >
          <option value="Ghana">Ghana</option>
          <option value="Togo">Togo</option>
          <option value="Nigerial">Nigeria</option>
        </select>
        <p v-if="$v.form.countriesMutualRecognitionBeCreated.$error">
          <small
            v-if="!$v.form.countriesMutualRecognitionBeCreated.required"
            class="text-danger"
            >This field is required</small
          >
        </p>
      </div>

      <div class="form-group">
        <label for="project directly"
          >Does your project contribute to overcoming the challenges with
          non-tariff physical barriers?
        </label>
        &nbsp; &nbsp;
        <div class="custom-control custom-radio custom-control-inline">
          <label
            ><input
              v-model="form.contributeToOvercomingChallenges"
              type="radio"
              name="challenger"
            />
            Yes</label
          >
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <label
            ><input
              v-model="form.contributeToOvercomingChallenges"
              type="radio"
              name="challenger"
            />
            No</label
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      form: {
        incorporateDigitizationInCustomProcesses: true,
        allowTrackingOfGoodsSendAcrossBorders: true,
        helpExtendBorderPostOpenTimes: true,
        allowForStandardsToBeRecognized: true,
        countriesMutualRecognitionBeCreated: [],
        contributeToOvercomingChallenges: true,
      },
    };
  },
  validations: {
    form: {
      countriesMutualRecognitionBeCreated: { required },
    },
  },
  methods: {
    emitFormData() {
      this.$emit("stepUpdated", {
        data: this.form,
        valid: !this.$v.$invalid,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>