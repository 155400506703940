<template>
  <div class="form_wrapper" id="home_wrapper ">
      <div class="row justify-content-center inner_container">
          <keep-alive>
              <component 
              :is="currentComponent"
              @previousComponent="previous"
              @stepUpdate="mergeData"
              >
            </component>
          </keep-alive>
          <!-- <non-tariffic-bar/>  -->
          <!-- <african-value-chain/> -->
          <!-- <AfricanContent /> -->
          <!-- <power/> -->
          <!-- <transport-project/> -->
          <!-- <ValueAdditionManufacturing/> -->
          <!-- <employement-creation/> -->
          <!-- <sdgAndScale /> -->
          <!-- <others/> -->
          <!-- <ownershipStructure />  -->
          <!-- <Connectivity /> -->
          <!-- <BasicDetails /> -->
          <!-- <BasicInfo /> -->
      </div>
  </div>
</template>

<script>
import BasicInfo from '../form/BasicInfo'
import ValueAdditionManufacturing from '../form/ValueAdditionManufacturing'
import AfricanValueChain from './AfricanValueChain.vue'
import EmployementCreation from './EmployementCreation.vue'
import NonTarifficBar from './NonTarifficBar.vue'
import Others from './Others.vue'
import Power from './Power.vue'
import TransportProject from './TransportProject.vue'
import sdgAndScale from './sdgAndScale.vue'
import ownershipStructure from './ownershipStructure'
import Connectivity from './Connectivity'
import AfricanContent from './AfricanContent'
import BasicDetails from './BasicDetails'

export default {
    components: {
        BasicInfo,
        BasicDetails,

        NonTarifficBar,
        AfricanValueChain,
        Power,
        TransportProject,
        ValueAdditionManufacturing,
        EmployementCreation,
        Others,
        sdgAndScale,
        ownershipStructure,
        Connectivity,
        AfricanContent
    },
    data() {
      return {
         currentComponent: 'BasicInfo',
        form: {

        }
      }
    },
    methods: {
      mergeData(data) {
        this.currentComponent = data.next
        // merge data here
        this.form = {...this.form,...data.form}
        localStorage.setItem('form',JSON.stringify(this.form))
      },
      previous(data) {
        this.currentComponent = data.name
      }
    }
}

</script>

<style>
.form_wrapper {
  width: 100%;
  background-image: url("../assets/bg.jpg");
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 5em 10%;
  box-shadow: 8px 8px rgba(0, 0, 0, 0.9);
}
.inner_container {
  opacity: 0.95;
  background-color: #fff;
  padding-top: 2em;
  padding-bottom: 2em;
}
button {
  outline: none;
  border: none;
  background: #000;
  font-weight: bolder;
  color: #fff;
  padding: 0.6em 3em;
}

.disabled{
  opacity: 0.5;
}
button{
    float:right;
}

</style>