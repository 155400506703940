<template>
  <div class="col-md-10">
    <h2 class="pb-2">Value addition and Manufacturing</h2>
    <hr />
    <el-form
      style="width: 100%"
      :model="form"
      :rules="rules"
      ref="form"
      status-icon
      label-position="left"
    >
      <div class="col-md-12">
        <el-form-item
          :filterable="true"
          prop="rawMaterial"
          label="Which type of raw material does your project deal with?"
        >
          <el-select
            style="width: 100%"
            v-model="form.rawMaterial"
            placeholder="select source of energy"
          >
            <el-option value="Primary-products" label="Primary products"
              >Primary products</el-option
            >
            <el-option value="semi-processed" label="semi-processed"
              >Semi Processed</el-option
            >
            <el-option value="processed-products" label="processed products"
              >processed products</el-option
            >
          </el-select>
        </el-form-item>
      </div>
      <div class="col-md-12">
        <el-form-item
          prop="resultInMassProductionOfValueAddedGoods"
          label="Will the project directly result in the mass production of value-added
        goods ?"
        >
          <el-radio-group
            v-model="form.resultInMassProductionOfValueAddedGoods"
          >
            <el-radio value="true" label="Yes"></el-radio>
            <el-radio value="false" label="No"></el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <div class="col-md-12">
        <el-form-item
          prop="connectToIndustrialOrEconomicZone"
          label="Will the project connect to/ be part of an industrial or economic
        zone?"
        >
          <el-radio-group v-model="form.connectToIndustrialOrEconomicZone">
            <el-radio value="true" label="Yes"></el-radio>
            <el-radio value="false" label="No"></el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="col-md-12">
        <el-form-item
          label="Indicate the specific industrialization areas that the project seeks to promote (Short Answer)"
          prop="industrializationAreasSeekToPromote"
        >
          <el-input
            type="textarea"
            :rows="2"
            placeholder="Please Indicate the specific industrialization areas that the project seeks to promote
"
            v-model="form.industrializationAreasSeekToPromote"
          >
          </el-input>
        </el-form-item>
      </div>

      <div class="col-md-12">
        <el-form-item
          :filterable="true"
          prop="typeOfValidationAddition"
          label="What is the type of value addition that your project creates"
        >
          <el-select
            style="width: 100%"
            v-model="form.typeOfValidationAddition"
            placeholder="select inputs"
          >
            <el-option value="Agro" label="Agro">Agro</el-option>
            <el-option value="Packing" label="Packing">Packing</el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="col-md-12">
        <el-form-item
          label="What is your country of incorporation?"
          prop="sourceOfMaterials"
        >
          <el-select
            :filterable="true"
            style="width: 100%"
            v-model="form.sourceOfMaterials"
            prop="sourceOfMaterials"
            placeholder="Select country"
          >
            <el-option
              v-for="(country, key) in countries"
              :key="key"
              :label="country.name"
              :value="country.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
          <el-button @click="previous" type="primary" style="float:left">
              previous
          </el-button>
          <el-button
          type="primary"
          @click="next('form')"
        >Next</el-button>
    </el-form>
  </div>
</template>

<script>
import VueCountrySelect from "vue-country-select";
import { mapGetters } from "vuex";
export default {
    components:{
        VueCountrySelect
    },
  data() {

    return {
        isValid: false,
      form: {
        rawMaterial: "",
        resultInMassProductionOfValueAddedGoods: '',//
        connectToIndustrialOrEconomicZone: "", //
        industrializationAreasSeekToPromote: "",
        typeOfValidationAddition: "",
        sourceOfMaterials: "",
      },
      rules: {
        rawMaterial: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
        resultInMassProductionOfValueAddedGoods: [
          {
            required: true,
            message: "Please this field is required",
            trigger: ["change", "blur"],
          },
        ],
        connectToIndustrialOrEconomicZone: [
          {
            required: true,
            message: "Please this field is required",
            trigger: ["change", "blur"],
          },
        ],
        industrializationAreasSeekToPromote: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
        typeOfValidationAddition: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
        sourceOfMaterials: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["countries"]),
  },
  created() {
    this.$store.dispatch("fetchCountries");
  },
  methods: {
    previous(){
             this.$emit('previousComponent', {
                 name:'TransportProject'
             })
        },
        next(form){
            this.$refs[form].validate( valid => {
                if( valid ) {
                  // this.form.resultInMassProductionOfValueAddedGoods === "Yes" ? this.form.resultInMassProductionOfValueAddedGoods = 'true' : this.form.resultInMassProductionOfValueAddedGoods = 'false'
                  // this.form.resultInMassProductionOfValueAddedGoods === "Yes" ? this.form.resultInMassProductionOfValueAddedGoods = 'true' : this.form.resultInMassProductionOfValueAddedGoods = 'false'
         // if validation successfully pass data to parent component and move to the next component
                    this.$emit('stepUpdate', {
                       next:'Power',
                       form: this.form
                    }) 
                    
                } else {
                    
                }
            })
        }
  }
};
</script>

<style lang="scss" scoped>
</style>