<template>
 <div class="col-md-10">
      <h2 class="pb-2">Ownership Structure</h2>
      <hr />
      <label for=""
        >Please indicate the category of shareholder and their percentage of
        ownership (Individuals, Government, Private Equity, Development Finance
        Institutions (DFIs), Other Institutional Shareholders?</label
      >
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="share-holder">Select Shareholders</label>
          <select id="inputState" class="form-control">
            <option selected>Choose...</option>
            <option>Individuals</option>
            <option>Government</option>
            <option>Private Equity</option>
            <option>Development Finance Institutions (DFIs)</option>
            <option>Other Institutional</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label for="percentage">Percentage</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter percentage here..."
            v-model="formData.shareHolderCategoryAndPercentage"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <select id="inputState" class="form-control">
            <option selected>Choose...</option>
            <option>Individuals</option>
            <option>Government</option>
            <option>Private Equity</option>
            <option>Development Finance Institutions (DFIs)</option>
            <option>Other Institutional</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <input
            type="text"
            class="form-control"
            placeholder="Enter percentage here..."
             v-model="formData.shareHolderCategoryAndPercentage"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <select id="inputState" class="form-control">
            <option selected>Choose...</option>
            <option>Individuals</option>
            <option>Government</option>
            <option>Private Equity</option>
            <option>Development Finance Institutions (DFIs)</option>
            <option>Other Institutional</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <input
            type="text"
            class="form-control"
            placeholder="Enter percentage here..."
             v-model="formData.shareHolderCategoryAndPercentage"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <select id="inputState" class="form-control">
            <option selected>Choose...</option>
            <option>Individuals</option>
            <option>Government</option>
            <option>Private Equity</option>
            <option>Development Finance Institutions (DFIs)</option>
            <option>Other Institutional</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <input
            type="text"
            class="form-control"
            placeholder="Enter percentage here..."
             v-model="formData.shareHolderCategoryAndPercentage"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <select id="inputState" class="form-control">
            <option selected>Choose...</option>
            <option>Individuals</option>
            <option>Government</option>
            <option>Private Equity</option>
            <option>Development Finance Institutions (DFIs)</option>
            <option>Other Institutional</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <input
            type="text"
            class="form-control"
            placeholder="Enter percentage here..."
            v-model="formData.shareHolderCategoryAndPercentage"
          />
        </div>
      </div>

      <!-- <div class="form-group">
        <label for="inputAddress"
          >Indicate your source(s) of funding (Debt, Equity, IGF, etc.)</label
        >
        <select id="inputState" class="form-control">
          <option selected>Choose...</option>
          <option>Debt</option>
          <option>Equity</option>
          <option>IGF</option>
        </select>
      </div> -->
      <div class="form-row">
        <label for=""
          >What is the percentage composition for each of the sources
          above?</label
        >
        <div class="form-group col-md-6">
          <label for="share-holder">Select Source of funding</label>
          <select v-model="formData.sourceOfFundingAndPercentage" id="inputState" class="form-control">
            <option selected>Choose...</option>
            <option>Debt</option>
            <option>Equity</option>
            <option>IGF</option>
            <option>Etc</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label for="percentage">Percentage</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter percentage here..."
          />
        </div>
      </div>
      <div class="form-group">
        <label for="inputAddress"
          >Indicate the kind of government support anticipated/received (Grants,
          Reliefs, Loans, Guarantees, Put or Call Option, Government consent and
          agreement, etc.)</label
        >
        <select v-model="formData.kindOfGOvernmentSupport" id="inputState" class="form-control">
          <option selected>Choose...</option>
          <option>Grants</option>
          <option>Reliefs</option>
          <option>Loans</option>
          <option>Guarantees</option>
          <option>Government consent and agreement</option>
        </select>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      formData:{
        sourceOfFundingAndPercentage:{},
        shareHolderCategoryAndPercentage:{},
        kindOfGOvernmentSupport:""
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>