<template>
    <div class="col-md-10">
        <h2 class="pb-2">SDG ALIGNMENT & SCALE </h2>
        <hr />
        <el-form
        style="width: 100%"
        :rules="rules"
        :model="form"
        ref="form"
        label-position="left"
        status-icon>
            <div class="row">
                <div class="col-md-12 p-5">
                    <h2></h2>
                        <el-form-item
                            prop="haveSocialOutcomeTargets"
                            label="Does your project have social/environmental outcome targets?"
                            >
                            <el-radio-group v-model="form.haveSocialOutcomeTargets">
                                <el-radio  label="Yes"></el-radio>
                                <el-radio  label="No"></el-radio>
                            </el-radio-group>
                        </el-form-item>
                </div>
                <div class="col-md-12 p-5">
                    <h4>Please complete below forms with the financials of the project for the first 5 years</h4>
                    <hr>
                     <div class="row">
                         <div class="col-md-12">
                              <el-form-item
                                label="Revenue"
                                prop="revenue"
                                >
                                <el-input type="number"  @change="handleInputData(1)" v-model="form.revenue" placeholder="Enter revenue in your currency"></el-input>
                                </el-form-item>
                         </div>
                         <div class="col-md-12">
                              <el-form-item
                                label="Loan"
                                prop="loan"
                                >
                                <el-input type="number"  @change="handleInputData(2)" placeholder="Enter loan in your currency" v-model="form.loan"></el-input>
                                </el-form-item>
                         </div>
                         <div class="col-md-12">
                              <el-form-item
                                label="Asset"
                                prop="asset"
                                >
                                <el-input  @change="handleInputData(3)" placeholder="Enter asset in your currency" type="number" v-model="form.asset"></el-input>
                                </el-form-item>
                         </div>
                         <div class="col-md-12">
                              <el-form-item
                                label="Profit"
                                prop="profit"
                                >
                                <el-input type="number"  @change="handleInputData(4)" v-model="form.profit" placeholder="Enter profit in your currency"></el-input>
                                </el-form-item>
                         </div>
                         <div class="col-md-12">
                              <el-form-item
                                label="Equity"
                                prop="equity"
                                >
                                <el-input type="number" v-model="form.equity" placeholder="Enter equity in your currency" @change="handleInputData(5)"></el-input>
                                </el-form-item>
                         </div>
                     </div>
                </div>
                
            </div>

           <div class="col-md-12">
                <el-button @click="previous" type="primary" style="float:left">
                    previous
                </el-button>
                <el-button
                type="primary"
                @click="next('form')"
                >Next</el-button>
            </div>

        </el-form>
    </div>
</template>

<script>
export default {
name:'sdgAndScale',
data() {
    return {
        form: {
            haveSocialOutcomeTargets:'',
            revenue:'',
            profit:'',
            asset:'',
            equity:'',
            loan:'',
            projectedFinancials:[]
        },
        rules: {
            haveSocialOutcomeTargets:[
                {required:true, message: 'Plsease this field is required', trigger:'change'}
            ],
            revenue:[
                {required:true, message: 'Plsease this field is required', trigger:'change'}
            ],
            asset:[
                {required:true, message: 'Plsease this field is required', trigger:'change'}
            ],
            loan:[
                {required:true, message: 'Plsease this field is required', trigger:'change'}
            ],
            equity:[
                {required:true, message: 'Plsease this field is required', trigger:'change'}
            ],
            profit:[
                {required:true, message: 'Plsease this field is required', trigger:'change'}
            ],
        }
    }
},
methods: {
        handleInputData(data) {
         
        }
        ,previous(){
             this.$emit('previousComponent', {
                 name:'EmployementCreation'
             })
        },
        next(form){
            this.$refs[form].validate( valid => {
                if( valid ) {
                    this.form.projectedFinancials = [{
                        name:'revenue',
                        value: this.form.revenue
                    },
                    {
                        name: 'loan',
                        value: this.form.loan
                    },
                    {
                        name:'asset',
                        value:this.form.asset
                    },
                    {
                        name:'equity',
                        value:this.form.equity
                    },
                    {
                        name:'profit',
                        value:this.form.profit
                    }
                    ]
                    // deleting unwated properties from an object
                    delete this.form.revenue
                    delete this.form.loan
                    delete this.form.asset
                    delete this.form.equity
                    delete this.form.profit

                     this.$emit('stepUpdate', {
                       next:'Others',
                       form: this.form
                    }) 

                } else {
                   
                }
             })
        }
    },
}
</script>

<style>

</style>