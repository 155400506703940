<template>
    <div class="col-md-10">
    <h2 class="pb-2">African Content</h2>
    <hr />
    <el-form
      style="width: 100%"
      :model="form"
      ref="form"
      status-icon
      label-position="left"
    >
    
        <div class="row">
            <div class="col-md-12">
                <p>Kindly indicate the number of people on your board and their nationalities </p>
            </div>
        </div>

        <div class="row" v-for="(data, key) in form.numberOfPeopleOnboardAndNationalities" :key="key">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-6 col-lg-6">
                        <el-form-item 
                            :rules="{required: true, message:'Please this field is required', trigger:'change'}"
                            :prop="'numberOfPeopleOnboardAndNationalities.' + key + '.nationality'"
                            >
                            <el-select placeholder="Select Nationality" v-model="data.nationality">
                                <el-option v-for="(country, key) in countries" :key="key" :label="country.name" :value="country.name">
                                    {{ country.name}} 
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
              
                <div class="col-md-6 col-sm-6 col-6 col-lg-6">
                    <el-form-item  
                    :rules="{required: true, message:'Please this field is required', trigger:'change'}"
                    :prop="'numberOfPeopleOnboardAndNationalities.' + key + '.numberOfPeople'"
                    >
                        <el-input type="number" v-model="data.numberOfPeople" placeholder ="Enter number of people"></el-input>
                    </el-form-item>
                </div>
                    
                </div>
            </div>
            <div class="col-md-2 mb-1" v-if="form.numberOfPeopleOnboardAndNationalities.length !== 1">
                <el-button style="float:right" type="danger" @click="deleteNationality" >Delete</el-button>
            </div>
            <div class="col-md-12" v-if="key === form.numberOfPeopleOnboardAndNationalities.length -1">
                    <el-button style="float:left" @click="newNationality" type="primary"  >Add</el-button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 pt-4">
                <p>What is the percentage gender composition on your board?</p>
                <el-form-item
                     v-for="(data, key) in form.percentageGenderCompositions"
                     :key="key" :label="data.gender"
                     :rules="{required: true, message:'Please this field is required', trigger:'change'}"
                     :prop="'percentageGenderCompositions.' + key + '.percentage'">
                    <el-input :placeholder="`Enter percentage of ${data.gender}s`" v-model="data.percentage">
                    </el-input>
                </el-form-item>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <el-form-item
                    prop="companyListed"
                    label="Is the Project Company Listed?"
                    :rules="{required: true, message:'Please this field is required', trigger:'change'}">
                    <el-radio-group v-model="form.companyListed">
                        <el-radio label="Yes"></el-radio>
                        <el-radio label="No"></el-radio>
                    </el-radio-group>
                </el-form-item>
            </div>
        </div>

        <div class="row" v-if="form.companyListed === 'Yes'">
            <div class="col-md-12">
                <el-form-item 
                    label="If Listed, please indicate the Stock Market and Trading Name"
                    prop="companyStockMarketAndTradingName"
                     :rules="{required: true, message:'Please this field is required', trigger:'change'}">
                    <el-input placeholder="Enter Stock Market Name and Trading Name" v-model="form.companyStockMarketAndTradingName"></el-input>
                </el-form-item>
            </div>
        </div>

        <div class="row" v-if="form.companyListed === 'No'">
            <div class="col-md-12">
                If Unlisted, Please Indicate the number of shareholders, their nationalities/countries of incorporation and percentage of ownership
            </div>
            <div class="col-md-12">
              <!-- ddd -->
              <div class="row" v-for="(data, key) in form.companyShareHoldersNationalitiesPercentages" :key="key">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-4 col-lg-4">
                            <el-form-item 
                                :rules="{required: true, message:'Please this field is required', trigger:'change'}"
                                :prop="'companyShareHoldersNationalitiesPercentages.' + key + '.nationality'"
                                >
                                <el-select placeholder="Select Nationality" v-model="data.nationality">
                                    <el-option v-for="(country, key) in countries" :key="key" :label="country.name" :value="country.name">
                                        {{ country.name}} 
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="col-md-4 col-sm-4 col-4 col-lg-4">
                            <el-form-item  
                            :rules="{required: true, message:'Please this field is required', trigger:'change'}"
                            :prop="'companyShareHoldersNationalitiesPercentages.' + key + '.number'"
                            >
                                <el-input type="number" v-model="data.number" placeholder ="Enter Number of shareholders"></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-md-4 col-sm-4 col-4 col-lg-4">
                            <el-form-item  
                            :rules="{required: true, message:'Please this field is required', trigger:'change'}"
                            :prop="'companyShareHoldersNationalitiesPercentages.' + key + '.percentage'"
                            >
                                <el-input type="number" v-model="data.percentage" placeholder ="Enter percentage"></el-input>
                            </el-form-item>
                        </div>
                        
                    </div>
                </div>
                <div class="col-md-2 mb-1" v-if="form.companyShareHoldersNationalitiesPercentages.length !== 1">
                    <el-button style="float:right" type="danger" @click="deleteShareholder" >Delete</el-button>
                </div>
                <div class="col-md-12" v-if="key === form.companyShareHoldersNationalitiesPercentages.length -1">
                        <el-button style="float:left" @click="newShareholder" type="primary"  >Add</el-button>
                </div>
            </div>
              <!-- dasfd -->
              
            </div>
        </div>
        <!-- senior members and their nationality -->
        <div class="row">
            <div class="col-md-12">
                <p>Indicate the number of senior management members and their nationalities</p>
            </div>
        </div>
        <div class="row" v-for="(data, key) in form.numberOfSeniorMembersAndTheirNationalities" :key="`some${key}`">
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-6 col-lg-6">
                        <el-form-item 
                            :rules="{required: true, message:'Please this field is required', trigger:'change'}"
                            :prop="'numberOfSeniorMembersAndTheirNationalities.' + key + '.nationality'"
                            >
                            <el-select placeholder="Select Nationality" v-model="data.nationality">
                                <el-option v-for="(country, key) in countries" :key="key" :label="country.name" :value="country.name">
                                    {{ country.name }} 
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
              
                <div class="col-md-6 col-sm-6 col-6 col-lg-6">
                    <el-form-item  
                    :rules="{required: true, message:'Please this field is required', trigger:'change'}"
                    :prop="'numberOfSeniorMembersAndTheirNationalities.' + key + '.number'"
                    >
                        <el-input type="number" v-model="data.number" placeholder ="Enter number of people"></el-input>
                    </el-form-item>
                </div>
                    
                </div>
                </div>
                <div class="col-md-2 mb-1" v-if="form.numberOfSeniorMembersAndTheirNationalities.length !== 1">
                    <el-button style="float:right" type="danger" @click="deleteEmployer" >Delete</el-button>
                </div>
                <div class="col-md-12" v-if="key === form.numberOfSeniorMembersAndTheirNationalities.length -1">
                        <el-button style="float:left" @click="newEmployer" type="primary"  >Add</el-button>
                </div>
        </div>
        <!--!end of senior members and their nationality -->
        <div class="row">
            <div class="col-md-12">
                <el-form-item 
                label="What is the ratio of non-African to African employees in the organization?"
                :rules="{required: true, message:'Please this field is required', trigger:'change'}"
                prop="nonAfricanAfricanRation">
                    <el-input type="text" v-model="form.nonAfricanAfricanRation" placeholder="eg , 1/2 ">
                    </el-input>
                </el-form-item>
            </div>
        </div>
        
        <div class="col-md-12">
            <el-button @click="previous" type="primary" style="float:left">
                previous
            </el-button>
            <el-button
            type="primary"
            @click="next('form')"
            >Next</el-button>
        </div>
        
    </el-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            form: { 
                numberOfPeopleOnboardAndNationalities:[
                    {
                        nationality:'',
                        numberOfPeople:''
                    },
                    
                ],
                percentageGenderCompositions:[
                    {

                        gender:'male',
                        percentange: ''
                    },
                    {
                        gender:'female',
                        percentage:''
                    }
                ],

                companyListed:'',
                companyStockMarketAndTradingName:'',
                companyShareHoldersNationalitiesPercentages:[
                    {
                        nationality:'',
                        number:'',
                        percentage:''
                    }
                ],
                nonAfricanAfricanRation:'',
                numberOfSeniorMembersAndTheirNationalities:[
                    {
                        nationality:'',
                        number: ''
                    },
                ]
                },
                
        }
    },
    methods: {
        newEmployer() {
            this.form.numberOfSeniorMembersAndTheirNationalities.push({
                nationality:'',
                number:'',
            })
        },
        deleteEmployer(key){
            this.form.numberOfSeniorMembersAndTheirNationalities.splice(key, 1)
        },
        newShareholder() {
            this.form.companyShareHoldersNationalitiesPercentages.push({
                nationality:'',
                number:'',
                percentage:''
            })
        },
        deleteShareholder(key){
            this.form.companyShareHoldersNationalitiesPercentages.splice(key, 1)
        },
        newNationality() {
            this.form.numberOfPeopleOnboardAndNationalities.push({
                nationality:'',
                numberOfPeople:''
            })
        },
        deleteNationality(key){
            this.form.numberOfPeopleOnboardAndNationalities.splice(key, 1)
        },
        // 
         previous(){
             this.$emit('previousComponent', {
                 name:'AfricanValueChain'
             })
        },
        next(form){
            this.$refs[form].validate( valid => {
                if( valid ) {
         // if validation successfully pass data to parent component and move to the next component
                    this.$emit('stepUpdate', {
                       next:'EmployementCreation',
                       form: this.form
                    }) 

                } else {
                    
                }
            })
        }
    },
    computed: {
        ...mapGetters(['countries'])
    },
    mounted() {
        this.$store.dispatch('fetchCountries')
    }

}
</script>

<style scoped>
</style>