<template>
  <div id="home_wrapper" class="shadow-sm">
    <div class="row justify-content-center inner_container">
      <!-- Form Steps Here!! -->
      <keep-alive>
        <!-- <BasicInfo v-if="currentStep === 1" ref="currentComponent" @stepUpdated="mergeStepsData" /> -->

       <BasiceDetails 
          v-if="currentStep === 2" 
          ref="currentComponent"
          @stepUpdated="mergeStepsData" /> 

          <!-- <AfricanValueChain v-if="currentStep === 3" ref="currentComponent" /> -->

        <!-- <african-content v-if="currentStep === 4" ref="currentComponent" /> -->

        <!-- <ownership-structure v-if="currentStep === 5" ref="currentComponent" /> -->

        <!-- <non-tariff-barries v-if="currentStep === 6" ref="currentComponent" /> -->

        <power v-if="currentStep === 7" ref="currentComponent" />

        <transport-project v-if="currentStep === 8" ref="currentComponent" /> 

        <value-addition-manufacturing
          v-if="currentStep === 9"
          ref="currentComponent"
        />

        <employement-creation
          v-if="currentStep === 10"
          ref="currentComponent"
        />
      </keep-alive>
        <!-- :disabled="!canProceed" :class="[!canProceed ? 'disabled': '']"  -->
      <!-- BUTTON CONTENT -->
      <div class="col-md-10">
        <div class="d-flex justify-content-between py-5">
          <button v-if="currentStep !== 1" @click="moveToPrevStep">Back</button>
          <button v-if="currentStep !== allSteps" @click="moveToNextStep">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BasicInfo from "../components/basicInfo";
import BasiceDetails from "../components/basicDetails";
import AfricanValueChain from "../components/africanValueChain.vue";
import AfricanContent from "../components/africanContent.vue";
import OwnershipStructure from "../components/ownershipStructure.vue";
import NonTariffBarries from "../components/projectFocusArea/nonTariffBarries.vue";
import Power from "../components/projectFocusArea/power.vue";
import TransportProject from "../components/projectFocusArea/transportProject.vue";
import ValueAdditionManufacturing from "../components/projectFocusArea/valueAdditionManufacturing.vue";
import EmployementCreation from "../components/createWealth/employementCreation.vue";

export default {
  name: "Home",
  components: {
    BasicInfo,
    BasiceDetails,
    AfricanValueChain,
    AfricanContent,
    OwnershipStructure,
    NonTariffBarries,
    Power,
    TransportProject,
    ValueAdditionManufacturing,
    EmployementCreation,
  },
  data() {
    return {
      currentStep: 1,
      allSteps: 11,
      canProceed: false,
      // form data
      form: {
        organizationName: '',
        emailAddress: '',
        telephoneNumber: "",
        nameOfContactPerson: "",
        emailAddressOfContactPerson: "",
        telephoneNumberOfContactPerson: "",
        sector: "",
        industry: "",
        country: "",
        companyAffiliatedWithBusinessAssociation: false,
        companyAffiliatedWithBusinessAssociationDetails: "",
        companyType: "",
        afcftaNumber: "",
        // second formData
        project_category: "",
        project_commenced_date: "",
        project_location: "",
        project_funding: "",
        project_sections: "",
        Project_completion_date: "",
        is_project_contractor: false,
        project_contractor: "",
        carriedOutInPhases:false,
        completionTimeForEachPhase:{
        }

      }
    };
  },
  methods: {
     mergeStepsData(step){
        this.form = {...this.form, ...step.data}
        this.canProceed = step.valid
      },
      
    moveToPrevStep() {
      this.currentStep--;
    },
    moveToNextStep() {
      this.currentStep++;

      this.$nextTick(() => {
        this.canProceed = !this.$refs["currentComponent"].$v.$invalid;
      });

    },
  },
};
</script>
<style scoped>
#home_wrapper {
  width: 100%;
  background-image: url("../assets/bg.jpg");
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 5em 10%;
  box-shadow: 8px 8px rgba(0, 0, 0, 0.9);
}
.inner_container {
  opacity: 0.95;
  background-color: #fff;
  padding-top: 2em;
  padding-bottom: 2em;
}
button {
  outline: none;
  border: none;
  background: #000;
  font-weight: bolder;
  color: #fff;
  padding: 0.6em 3em;
}

.disabled{
  opacity: 0.5;
}
</style>