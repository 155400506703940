<template>
  <div class="col-md-10">
    <h2 class="pb-2">Employment Creation</h2>
    <hr />
    <el-form
      style="width: 100%"
      :rules="rules"
      :model="form"
      ref="form"
      label-position="left"
      status-icon>

      <div class="form-group col-md-12">
        <el-form-item
          label="How many employees will your project engage, overall?"
          prop="numbenumberOfEmployeesToEngager"
        >
          <el-input
            type="number"
            v-model="form.numberOfEmployeesToEngage"
            placeholder="Enter number of  employees will your project engage"
          ></el-input>
        </el-form-item>
      </div>

      <div class="form-group col-md-12">
        <el-form-item
          label="How many non-direct employees will your project engage
        downstream/across  the integrated chain of the project"
          prop="numberOfNonDirectEmployees"
        >
          <el-input
            type="number"
            v-model="form.numberOfNonDirectEmployees"
          ></el-input>
        </el-form-item>
      </div>

      <div class="form-group col-md-12">
        <el-form-item
          label="What is the estimated market size that you expect your
        project/investment to address?"
          prop="estimatedMarketSize"
        >
          <el-input type="number" v-model="form.estimatedMarketSize"></el-input>
        </el-form-item>
      </div>

      <div class="col-md-12">
        <el-form-item
          prop="employeeProfitOrSharesScheme"
          label="Does the company have an employee profit or shares scheme?"
        >
          <el-radio-group v-model="form.employeeProfitOrSharesScheme">
            <el-radio value="true" label="Yes"></el-radio>
            <el-radio value="false" label="No"></el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <div class="col-md-12">
        <el-form-item
          prop="companyListedOnStockExchange"
          label="Is the company listed or intend to list on the stock exchange?"
        >
          <el-radio-group v-model="form.companyListedOnStockExchange">
            <el-radio value="true" label="listed"></el-radio>
            <el-radio value="false" label="intend to list"></el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="form-group col-md-12">
        <el-form-item
          label="How is the value chain integrated into the existing value chains in the
        country/continent?"
          prop="howValueChainIntegrated"
        >
          <el-input
            type="textarea"
            v-model="form.howValueChainIntegrated"
            placeholder="How is the value chain integrated into the existing value chains in the
        country/continent?"
          ></el-input>
        </el-form-item>
      </div>
      <div class="col-md-12">
        <el-form-item
          prop="dependOnProprietaryTechnology"
          label="Will this project depend on proprietary technology or licensing from other entities?"
        >
          <el-radio-group v-model="form.dependOnProprietaryTechnology">
            <el-radio  label="Yes"></el-radio>
            <el-radio  label="No"></el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="col-md-12" v-if="form.dependOnProprietaryTechnology === 'Yes'">
        <el-form-item
          label="If yes, which of these countries does the ownership originate from?"
          prop="countriesOwnershipOriginateFrom"
        >
          <el-select
            :filterable="true"
            style="width: 100%"
            v-model="form.countriesOwnershipOriginateFrom"
            prop="country"
            placeholder="Select country"
            :multiple="true"
          >
            <el-option
              v-for="(country, key) in countries"
              :key="key"
              :label="country.name"
              :value="country.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="form-group col-md-12">
        <el-form-item
          label="What is the estimated percentage of dividends/profits to African
        shareholders or equity contributors from Africa?"
          prop="estimatedPercentageOfDividends"
        >
          <el-input type="number" v-model="form.estimatedPercentageOfDividends"></el-input>
        </el-form-item>
      </div>


      <div class="form-group col-md-12">
        <el-form-item
          label="What is the estimated total spend of the project in Africa?"
          prop="estimatedTotalSpend"
        >
          <el-input type="number" placeholder="What is the estimated total spend of the project in Africa?" v-model="form.estimatedTotalSpend"></el-input>
        </el-form-item>
      </div>

       <div class="col-md-12">
        <el-form-item
          prop="haveSocialOutcomeTargets"
          label="Does your project have social/environmental outcome targets?"
        >
          <el-radio-group v-model="form.haveSocialOutcomeTargets">
            <el-radio value="true" label="Yes"></el-radio>
            <el-radio value="false" label="No"></el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <!-- Last question here! is dynamic form -->
     <div class="col-md-12">
            <el-button @click="previous" type="primary" style="float:left">
                previous
            </el-button>
            <el-button
            type="primary"
            @click="next('form')"
            >Next</el-button>
      </div>

    </el-form>
  </div>
</template>

<script>
import VueCountrySelect from "vue-country-select";
import { mapGetters } from "vuex";
export default {
  components: {
    VueCountrySelect,
  },
  data() {
    return {
      form: {
        numberOfEmployeesToEngage: "",
        numberOfNonDirectEmployees: "",
        estimatedMarketSize: "",
        employeeProfitOrSharesScheme: '',
        companyListedOnStockExchange: '',
        howValueChainIntegrated: "",
        dependOnProprietaryTechnology: '',
        countriesOwnershipOriginateFrom: [],
        estimatedPercentageOfDividends: "",
        estimatedTotalSpend: "",
        haveSocialOutcomeTargets: '',
        projectedFinancials: "",
      },
      rules:{
        numberOfEmployeesToEngage:[
          {required:true, message:'Please this field is required', trigger:'change'}
        ],
         numberOfNonDirectEmployees:[
          {required:true, message:'Please this field is required', trigger:'change'}
        ],
        estimatedMarketSize:[
          {required:true, message:'Please this field is required', trigger:'change'}
        ],
        employeeProfitOrSharesScheme:[
          {required:true, message:'Please this field is required', trigger:'change'}
        ],
         companyListedOnStockExchange:[
          {required:true, message:'Please this field is required', trigger:'change'}
        ],
        howValueChainIntegrated:[
          {required:true, message:'Please this field is required', trigger:'change'}
        ],
        dependOnProprietaryTechnology:[
          {required:true, message:'Please this field is required', trigger:'change'}
        ],
        countriesOwnershipOriginateFrom:[
          {required:true, message:'Please this field is required', trigger:'change'}
        ],
        estimatedPercentageOfDividends:[
          {required:true, message:'Please this field is required', trigger:'change'}
        ],
        estimatedTotalSpend:[
          {required:true, message:'Please this field is required', trigger:'change'}
        ],
        haveSocialOutcomeTargets:[
          {required:true, message:'Please this field is required', trigger:'change'}
        ],
        projectedFinancials:[
          {required:true, message:'Please this field is required', trigger:'change'}
        ],

      }
    };
  },
  computed: {
    ...mapGetters(["countries"]),
  },
  created() {
    this.$store.dispatch("fetchCountries");
  },
  methods: {
        // 
         previous(){
             this.$emit('previousComponent', {
                 name:'AfricanContent'
             })
        },
        next(form){
            this.$refs[form].validate( valid => {
                if( valid ) {
         // if validation successfully pass data to parent component and move to the next component
                    this.$emit('stepUpdate', {
                       next:'sdgAndScale',
                       form: this.form
                    }) 

                } else {
                    
                }
            })
        }
  }
};
</script>

<style lang="scss" scoped>
</style>